const initialState = { data: [], isLoading: true };

const setResourceLimitGraphData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESOURCE_LIMIT_GRAPH_DATA":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setResourceLimitGraphData;
