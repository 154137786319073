import React from 'react'
import Sidebar from '../components/SideBar/Sidebar'
import DownloadsComponent from './pageComponents/DownloadsComponent'

const DownloadPage = () => {
  return (
    <section className="row-flex">
    <div className="sidebarContainer">
      <Sidebar />
    </div>
    <div className="MainContainer">
      <DownloadsComponent />
    </div>
  </section>
  )
}

export default DownloadPage