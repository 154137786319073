import React, { useEffect } from "react";
import "./App.css";
import "./Grid.css";
import ViewsConatiner from "./Views/ViewsContainer";
import {
  AuthProvider,
  AuthConfiguration,
  AuthModel,
  AuthEvents,
} from "@iaedge/auth";
import { Button, Dialog } from "@abb/abb-common-ux-react";
import { envSettings } from "./EnviornmentSettings/Settings";
// import Store from "./Store";
import Store from "./reduxStore/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

export interface IAuthHelperModel {
  getAccessToken(): string;
  getTenant(): string;
  logOut(): void;
}
export const commonAuthHelper: IAuthHelperModel = {
  getAccessToken: () => "",
  getTenant: () => "",
  logOut: () => "",
};

const App = () => {
  let authModel = {} as AuthModel;
  if (envSettings.connectivityMode !== "connected") {
    authModel = {
      authority: envSettings.authority,
      clientId: envSettings.oidc_clientId,
      redirectUrl: envSettings.oidc_redirectUri,
      scope: "openid profile offline_access",
      postLogoutRedirectUrl: envSettings.postLogoutRedirectUri,
      tokenRenewalOffsetSeconds: 10,
    };
  } else {
    authModel = {
      b2cName: envSettings.b2cName,
      clientId: envSettings.clientId,
      redirectUrl: envSettings.redirectUri,
      postLogoutRedirectUrl: envSettings.postLogoutRedirectUri,
      scope: `https://${envSettings.b2cName}.onmicrosoft.com/${envSettings.solution}/instance`,
      tokenRenewalOffsetSeconds: 10,
    };
  }
  const config: AuthConfiguration = {
    authType:
      envSettings.connectivityMode !== "connected" ? "OIDC" : "AbilityB2C",
    storage: "localStorage",
    applicationName: "Edgenius Management Portal",
    authModel: {
      ...(authModel as any),
    },
    keepLiveSession: false,
    onEventRegister: (methods: AuthEvents) => {
      commonAuthHelper.getAccessToken = methods.getToken;
      commonAuthHelper.getTenant = methods.getTenantName;
      commonAuthHelper.logOut = methods.logout;
    },
  };
  if (envSettings.isAuthenticationEnabled) {
    if (
      envSettings.connectivityMode === "connected" &&
      localStorage.getItem("currVersion") &&
      localStorage.getItem("currVersion") !== null &&
      localStorage.getItem("currVersion") !== envSettings.version
    ) {
      return (
        <div>
          <Dialog
            showCloseButton={false}
            closeOnEscape={false}
            closeOnLostFocus={false}
            dimBackground={true}
            isOpen={true}
            title="Warning !"
            className="warning-dialog-modal"
          >
            <div>
              Version has been updated. Click here to update the app.
              <Button
                sizeClass="medium"
                type="normal"
                className="btn-cancel-warning-modal"
                onClick={() => {
                  let edgedetailsValue = localStorage.getItem("/edgedetails");

                  localStorage.clear();
                  sessionStorage.clear();
                  window.location.reload();
                  localStorage.setItem(
                    "/edgedetails",
                    JSON.stringify(edgedetailsValue)
                  );
                }}
                text={"Update"}
              />
            </div>
          </Dialog>
        </div>
      );
    } else {
      localStorage.setItem("currVersion", envSettings.version);
      return (
        <div className="App" style={{ padding: "0px 0px" }}>
          <AuthProvider {...config}>
            <AppContainer />
          </AuthProvider>
        </div>
      );
    }
  } else {
    return (
      <div className="App" style={{ padding: "0px 10px" }}>
        <AppContainer />
      </div>
    );
  }
};

export const AppContainer = () => {
  return (
    <Provider store={Store}>
      <BrowserRouter basename={`${envSettings.prefix}`}>
        <ViewsConatiner />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
