import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import { Col, Row, Select } from "antd";
import AboutPage from "../components/AboutPage/AboutPage";

function InformationPage() {
  return (
    <section className="row-flex" style={{ display: "flex", height: "90vh" }}>
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer" style={{ flex: "1", padding: "20px" }}>
        <AboutPage />
      </div>
    </section>
  );
}

export default InformationPage;
