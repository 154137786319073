const initialState = {"data":[],"isLoading":true};

const setInventory = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_INVENTORY":
            return state = action.payload
        default:
            return state;
    }
}

export default setInventory;
