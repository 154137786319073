const initialState = [];

const setEdgeAdminSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EDGE_ADMIN_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default setEdgeAdminSettings;