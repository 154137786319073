
const initialState = {type:'', payload:{}};

const setRoutesModels = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_ROUTES_MODEL":
            return state = action.payload
        default:
            return state;
    }
}

export default setRoutesModels;