
const initialState = 0;

const setApiReloader = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_API_RELOAD":
            return state = action.payload
        default:
            return state;
    }
}

export default setApiReloader;