import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { IState } from "../../../Reducer";
import "./UnAuthorizedView.css";

const UnAuthorisedView = (props: any) => {
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <div className="unauthorized-view">
      {commonState.unAuthorizedErrorType === "403"
        ? "User is not authorized to view this page."
        : commonState.unAuthorizedErrorType === "401"
        ? "Failed to authenticate. Please logout and login again."
        : ""}
    </div>
  );
};
export default withRouter(UnAuthorisedView);
