import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart, LabelList } from "recharts";
import Heading from "../SectionHeading/Heading";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";

function Widget() {
  const [chartsLoad, setChartLoad] = useState(true);

  const AllApplicationSummary = useSelector(
    (state) => state.setEdgeStatus.data
  );
  const isLoading = useSelector((state) => state.setEdgeStatus.isLoading);

  const COLORS = [
    General_color.success,
    General_color.warning,
    General_color.error,
    General_color.info,
  ];
  const [TotalApplicationStats_cpu, SetTotalApplicationStats_cpu] = useState(
    []
  );
  const [TotalApplicationStats_ram, SetTotalApplicationStats_ram] = useState(
    []
  );
  const [cpuUtilization, setCpuUtilization] = useState();
  const [ramUtilization, setRamUtilization] = useState();
  const [totalRam, setTotalram] = useState();

  const generatePod_PieChart = (statusData) => {
    // const filteredStatusData = statusData.filter((entry) => entry.value > 0); // Filter out zero values
    const filteredStatusData = statusData;

    return (
      <PieChart width={168} height={168}>
        <Pie
          data={filteredStatusData}
          cx={80}
          cy={80}
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {filteredStatusData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          {/* <LabelList
            dataKey="value"
            position="inside"
            formatter={(value) => value.toFixed(1)}
            style={{ fill: "#000", fontSize: "12px" }}
          /> */}
        </Pie>
      </PieChart>
    );
  };

  const generateStats = (statData) => {
    return statData.map((stat, index) => (
      <div className="eachStat" key={index}>
        <span
          className="colorReceptor"
          style={{ backgroundColor: `${COLORS[index % COLORS.length]}` }}
        ></span>
        <span className="legendText">
          <span>{stat?.name}</span>
          <span>{`${stat?.value?.toFixed(2)}`}</span>
        </span>
      </div>
    ));
  };

  useEffect(() => {
    const dataHere = AllApplicationSummary?.edgeServiceMetricsSummary;
    const cpuUsed =
      dataHere?.applicationCPUTotal +
      dataHere?.othersCPU +
      dataHere?.platformCPUTotal;
    const ramUsed =
      dataHere?.applicationRamTotal +
      dataHere?.othersRAM +
      dataHere?.platformRamTotal;
    const fullRam =
      dataHere?.applicationRamTotal +
      dataHere?.availableRAM +
      dataHere?.othersRAM +
      dataHere?.platformRamTotal;
    SetTotalApplicationStats_cpu([
      {
        name: "Application",
        value: dataHere?.applicationCPUTotal,
      },
      { name: "Available", value: dataHere?.availableCPU },
      { name: "Others", value: dataHere?.othersCPU },
      { name: "Platform", value: dataHere?.platformCPUTotal },
    ]);
    setCpuUtilization(cpuUsed);
    SetTotalApplicationStats_ram([
      { name: " Application", value: dataHere?.applicationRamTotal },
      { name: "Available", value: dataHere?.availableRAM },
      { name: "Others", value: dataHere?.othersRAM },
      { name: "Platform", value: dataHere?.platformRamTotal },
    ]);
    setRamUtilization(ramUsed);
    setTotalram(fullRam);
    if (dataHere) {
      setChartLoad(false);
    }
  }, [AllApplicationSummary]);

  return (
    <div style={{ overflow: "hidden", height: "-webkit-fill-available" }}>
      <span
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          columnGap: "10px",
          width: "100%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <span>
          <Heading text={`Edge Resources`} />
          {isLoading ||
            (chartsLoad && <SpinnerLoader style={{ paddingLeft: "10px" }} />)}
        </span>
      </span>
      {!chartsLoad &&
        !isLoading &&
        AllApplicationSummary?.edgeServiceMetricsSummary
          ?.edgeApplicationPodMetrics?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "80%",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <div
              className="leftPieChart"
              style={{ borderRight: "1px solid #f0f0f0" }}
            >
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <span
                    className="chartHeading"
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      scale: "0.8",
                    }}
                  >
                    {" "}
                    <b style={{ fontSize: "12px" }}>{`${cpuUtilization.toFixed(
                      1
                    )}%`}</b>
                  </span>
                  <div
                    style={{
                      position: "relative",
                      height: "168px",
                      width: "168px",
                      scale: "0.8",
                    }}
                  >
                    {generatePod_PieChart(TotalApplicationStats_cpu)}
                  </div>
                </div>
                <div
                  style={{
                    // width: "80%",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "ABBVoice-medium",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    CPU Utilization %
                  </span>
                  <div
                    className="statWrapper scaleDown"
                    style={{ marginTop: "10px" }}
                  >
                    {generateStats(TotalApplicationStats_cpu)}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightPieChart">
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <span
                    className="chartHeading"
                    style={{
                      fontSize: "17px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      scale: "0.8",
                    }}
                  >
                    {" "}
                    <b style={{ fontSize: "12px" }}>
                      {ramUtilization != 0
                        ? `${ramUtilization.toFixed(1)}GB / 
            ${totalRam.toFixed(1)}GB`
                        : "- / -"}{" "}
                    </b>
                  </span>

                  <div
                    style={{
                      position: "relative",
                      height: "168px",
                      width: "168px",
                      scale: "0.8",
                    }}
                  >
                    {generatePod_PieChart(TotalApplicationStats_ram)}
                  </div>
                </div>
                <div
                  style={{
                    // width: "80%",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "ABBVoice-medium",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    RAM Utilization GB
                  </span>
                  <div
                    className="statWrapper scaleDown"
                    style={{ marginTop: "10px" }}
                  >
                    {generateStats(TotalApplicationStats_ram)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {!isLoading &&
        AllApplicationSummary?.edgeServiceMetricsSummary
          ?.edgeApplicationPodMetrics?.length == 0 && <NoDataComponent />}

      {AllApplicationSummary?.edgeServiceMetricsSummary
        ?.edgeApplicationPodMetrics == null &&
        !isLoading && <NoDataComponent />}
    </div>
  );
}

export default Widget;
