
const initialState = false;

const setIsEdgeDeploying = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EDGE_DEPLOYING":
            return state = action.payload
        default:
            return state;
    }
}

export default setIsEdgeDeploying;