import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "antd";
import "./LandingCards.css";

const cardData = [
  {
    title: "System Inventory",
    description: "Centralized management of system inventory",
    link: "/inventory",
  },
  {
    title: "Applications",
    description: "Discover Edgenius apps and onboard new",
    link: "/alltenantapps",
  },
  {
    title: "Documentation",
    description:
      "Access detailed guides and resources for centralized Edge management",
    link: "/information?tab=userguide",
  },
  {
    title: "Download",
    description: "Get essential files and software",
    link: "/downloads",
  },
];

const LandingCards = () => {
  const [customHeight, setCustomHeight] = useState(0);
  let rtime;
  let timeout = false;
  let delta = 100;
  const adjustHeight = () => {
    let getMainContainer;
    let finalHeight;
    getMainContainer = document?.getElementsByClassName("gridContainer");
    finalHeight = getMainContainer?.[0]?.clientHeight - 45;
    setCustomHeight(finalHeight);
  };
  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }
  useEffect(() => {
    resizeend();
  }, []);
  return (
    <div>
      <Row
        gutter={[16, 16]}
        justify="center"
        className="landing-cards-container"
      >
        {cardData.map((card, index) => (
          <Col key={index} onClick={() => (window.location.href = card.link)}>
            <Card className="landing-card" hoverable>
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LandingCards;
