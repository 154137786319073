import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showEdgeSettings } from '../../reduxStore/actions';
import { RiSettings5Fill } from 'react-icons/ri';

function ConfigurationError() {
  const dispatch = useDispatch();
  let isEdgeDeploying = useSelector(state=>state.setIsEdgeDeploying);
  return (
    <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
                fontFamily: "ABBVoice-medium",
                // color: "#d2d2d2",
              }}
            >
              <div style={{ padding: "20px",  display: "flex",
                alignItems: "center",
                justifyContent: "center", gap:'2px' }}>
                <span>
                  Edge is available for first configuration. Please setup the edge
                using edge settings </span>
                <button
              style={{
                cursor: "pointer",
                marginLeft: "auto",
                fontSize: "22px",
                marginTop:'5px',
                background: 'unset',
                border: 'none'

              }}
              onClick={() => {
                dispatch(showEdgeSettings({ payload: true }));
              }}
              disabled={isEdgeDeploying}
            >
              <RiSettings5Fill />
            </button> 
            {/* <span>  for first time use.</span> */}
              </div>
            </div>
  )
}

export default ConfigurationError