import React from "react";
import DashboardDetails from "../../components/DashboardDetails/Dashboarddetails";
import LandingCards from "../../components/LandingCards/LandingCards";
import { Col, Row } from "antd";

function LandingComponents({ inventoryData, inventoryLoading }) {
  return (
    <>
      <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
        <Col
          flex="1 1 525px"
          style={{
            columnGap: "10px",
            display: "grid",
            rowGap: "10px",
          }}
        >
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                borderRadius: "10px",
                height: "10vh",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "28px",
                fontFamily: "ABBVoice-bold",
              }}
            >
              Edgenius Management Portal
            </Col>
          </Row>

          {/* Adjusted DashboardDetails Component */}
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                borderRadius: "10px",
                height: "15vh",
              }}
            >
              <DashboardDetails inventoryData={inventoryData} />
            </Col>
          </Row>

          {/* Adjusted LandingCards Component */}
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                borderRadius: "10px",
                height: "66vh",
              }}
            >
              <div style={{ padding: "20px" }}>
                <LandingCards />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LandingComponents;
