import React from 'react'
import Sidebar from '../components/SideBar/Sidebar'
import { Col, Row } from 'antd'
import AuditLogPageComponent from './pageComponents/AuditLogPageComponent'

function AuditLogPage() {
  return (
    <section className="row-flex">
    <div className="sidebarContainer">
      <Sidebar />
    </div>
    <div className="MainContainer">
 

      <Row style={{ gridGap: "10px", paddingRight:'20px' }}>
      <Col flex="1 1 300px" style={{ display:'grid', Gap: "10px",  height: '90vh'}}  className="gridContainer">
        <AuditLogPageComponent />
      </Col>
    </Row>

    </div>
  </section>
  )
}

export default AuditLogPage