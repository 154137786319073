import Heading from "../SectionHeading/Heading";
import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import WarningIcons from "../WarningIcons/WarningIcons";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { setisAllDependenciesInstalled } from "../../reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const AppDependency = (props) => {
  const dispatch = useDispatch();
  const dependencyData = useSelector(
    (state) => state?.setisAllDependenciesInstalled
  );
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const settingData = queryParam.get("settingData");
  const _settingData = JSON.parse(settingData);

  const renderStatusIcon = (status) => {
    if (status) {
      return (
        <Tooltip title="Installed">
          <WarningIcons type="good" />{" "}
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Not installed">
          <WarningIcons type="critical" />{" "}
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      title: "Application Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{renderStatusIcon(status)}</span>,
    },
  ];
  const [dataSource, setdataSource] = useState([]);
  const [installedCount, setinstalledCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  useEffect(() => {
    console.log("depssss", dependencyData);
    if (dependencyData?.dependencies == undefined) {
    } else {
      let temp_dataSource = dependencyData
        ? Object?.keys(dependencyData?.dependencies)?.map((key) => ({
            key: key,
            name: key.split(",")[1],
            status: dependencyData.dependencies[key],
          }))
        : [];
      setdataSource(temp_dataSource);
      setinstalledCount(temp_dataSource?.filter((app) => app?.status)?.length);
      settotalCount(temp_dataSource?.length);
    }
  }, [dependencyData]);

  return (
    <section className="App" style={{ padding: 0 }}>
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "96%%",
          borderBottom: "1px solid #f0f0f0",
          marginBottom: "20px",
        }}
      >
        <Heading text="Application Dependencies" />
      </div>

      <div className="dependency-container">
        {/* {dependencyData !== undefined && (
          <span
            style={{
              fontFamily: "ABBVoice",
              fontSize: "15px",
              marginLeft: "10px",
            }}
          >
            {totalCount !== 0 &&
              `${installedCount}/${totalCount} applications installed`}
          </span>
        )} */}
        <Table
          style={{ marginTop: "10px" }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
        />
      </div>
    </section>
  );
};

export default AppDependency;
