const initialState = {};

const setTempRoutes = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_TEMP_ROUTE":
            return state = action.payload
        default:
            return state;
    }
}

export default setTempRoutes;