const initialState = {type:'',payload:{}};

const setAuthenticationSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_AUTHENTICATION_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default setAuthenticationSettings;