import React from 'react'
import style from "./Heading.css";
function Heading({text, additionalClass}) {

  if(additionalClass==undefined){
    return <span className="TextHeading" >{text}</span>
  }else{

    return (
      
      <span className={`TextHeading ${additionalClass}`} >{text}</span>
      )
    }
}

export default Heading