const initialState = {"data":[], "isLoading":true};

const setEventLog = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EVENTLOG":
            return state = action.payload
        default:
            return state;
    }
}

export default setEventLog;