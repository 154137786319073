const initialState = {"data":[],"isLoading":true};

const setEdgeDetails = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EDGE_DETAILS":
            return state = action.payload
        default:
            return state;
    }
}

export default setEdgeDetails;
