
const initialState = 0;

const setInstallAppsReloader = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_INSTALL_APP_RELOAD":
            return state = action.payload
        default:
            return state;
    }
}

export default setInstallAppsReloader;