export const routes = {
  LANDING_PAGE: "/",
  DASHBOARD_PAGE: "/inventory",
  ALLEDGES_PAGE: "/alledges",
  ALLAPPLICATIONS_PAGE: "/allapplications",
  EDGEDETAILS: "/edgedetails",
  SETTINGS_PAGE: "/settings",
  ALLTENANT_APPS_PAGE: "/alltenantapps",
  DOWNLOADS: "/downloads",
  INFORMATION: "/information",
  AUDITLOG:'/auditlog',
  SOFTWARE_UPDATE:"/softwareupdate",
  EDGE_RESOURCES:"/edgeResources",
  APP_DETAILS:'/appDetails',
  RESOURCE_PAGE: "/edgeResources",
};
