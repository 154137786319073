import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

function SpinnerLoader({style}) {
  return (
    <Spin style={style} indicator={antIcon} />
  )
}

export default SpinnerLoader