const initialState = '';

const deploymentProgess = (state= initialState, action) =>{
    switch (action.type) {
        case "CONFIRM_DEPLOYMENT":
            return state = action.payload
        default:
            return state;
    }
}

export default deploymentProgess;