import React from 'react'
import Sidebar from '../components/SideBar/Sidebar'
import SoftwareVersionsComponents from './pageComponents/softwareupdatesComponents'

function SoftwareVersions() {
  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <SoftwareVersionsComponents />
      </div>
    </section>
  )
}

export default SoftwareVersions