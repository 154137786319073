import React from "react";
import Heading from "../SectionHeading/Heading";
import { IoIosRocket } from "react-icons/io";
import { GrUpdate } from "react-icons/gr";
import { RiSettings5Fill, RiDeleteBin6Fill } from "react-icons/ri";
import { setEdgeDetails, showEdgeSettings } from "../../reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import "./Widget.css";
import { General_color } from "../../constants/GeneralConstants";
import { Tooltip } from "antd";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";

function Widget() {
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  let isLoading_edge = useSelector((state) => state.setEdgeDetails.isLoading);
  let isLoading_inventory = useSelector(
    (state) => state.setInventory.isLoading
  );
  let isEdgeDeploying = useSelector((state) => state.setIsEdgeDeploying);
  let edgeStatus = useSelector((state) => state.setEdgeStatus);
  let isPlatformStatusLoading = edgeStatus?.isloading;
  let platformServiceStatus = edgeStatus?.data?.platformServiceStatus;
  let platformType = useSelector(
    (state) => state.setEdgeDetails?.data?.[4]?.detail
  );
  return (
    <div className="App">
      <span className="heading-container">
        <Heading text="Actions" />
        {(isLoading_edge || isLoading_inventory) && <SpinnerLoader />}
      </span>
      {!isLoading_edge && !isLoading_inventory && !isPlatformStatusLoading && (
        <div style={{ overflowX: "hidden", overflowY: "auto", height: "18vh" }}>
          <div className="quickLinks-container">
            <div className="linkicon">
              {true && !isLoading_edge && (
                <Tooltip title="Manage & Configure Edge Settings">
                  <RiSettings5Fill
                    onClick={(e) => {
                      if (platformType == "unknown") {
                        dispatch(showEdgeSettings({ payload: true }));
                      } else if (
                        !isEdgeDeploying &&
                        platformServiceStatus === "Running"
                      ) {
                        dispatch(showEdgeSettings({ payload: true }));
                      } else {
                        e.preventDefault();
                      }
                    }}
                    style={{
                      fontSize: "30px",
                      color:
                        platformType == "unknown"
                          ? "#1f1f1f"
                          : isEdgeDeploying ||
                            platformServiceStatus !== "Running"
                          ? General_color.disable
                          : "#1f1f1f",
                    }}
                    className="icon"
                  />
                </Tooltip>
              )}
              <span className="description">Edge Settings</span>
            </div>
            <div className="linkicon">
              <a
                href={
                  platformServiceStatus !== "Running"
                    ? "#"
                    : `/allapplications?edgeId=${edgeId}`
                }
                className="icon-link"
                onClick={(e) => {
                  if (platformServiceStatus !== "Running") {
                    e.preventDefault();
                  }
                }}
              >
                <Tooltip title="Manage & Deploy applications">
                  <IoIosRocket
                    style={{
                      fontSize: "30px",
                      marginBottom: "5px",
                      cursor:
                        platformServiceStatus !== "Running"
                          ? "not-allowed"
                          : "pointer",
                      color:
                        platformServiceStatus !== "Running"
                          ? General_color.disable
                          : "#1f1f1f",
                    }}
                    className="icon"
                  />
                </Tooltip>
              </a>
              <span className="description">Manage Applications</span>
            </div>
            <div className="linkicon">
              <a
                href={
                  platformServiceStatus !== "Running"
                    ? "#"
                    : `/softwareupdate?edgeId=${edgeId}`
                }
                className="icon-link"
                onClick={(e) => {
                  if (platformServiceStatus !== "Running") {
                    e.preventDefault();
                  }
                }}
              >
                <Tooltip title="Check for updates">
                  <GrUpdate
                    style={{
                      fontSize: "20px",
                      cursor:
                        platformServiceStatus !== "Running"
                          ? "not-allowed"
                          : "pointer",
                      color:
                        platformServiceStatus !== "Running"
                          ? General_color.disable
                          : "#1f1f1f",
                    }}
                    className="icon"
                  />
                </Tooltip>
              </a>
              <span className="description">Software Update</span>
            </div>
            <div className="linkicon">
              <Tooltip title="Currently disabled">
                <RiDeleteBin6Fill
                  disable={true}
                  style={{
                    fontSize: "25px",
                    color: General_color.disable,
                    cursor: "pointer",
                  }}
                  className="icon"
                />
              </Tooltip>
              <span className="description">Delete Edge</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Widget;
