
const initialState = false;

const isEdgeUpdateAvailable = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EDGE_UPDATE_AVAILABLE":
            return state = action.payload
        default:
            return state;
    }
}

export default isEdgeUpdateAvailable;