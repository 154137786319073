const initialState = {
  applicationDetails: {
    name: "",
    description: "",
    version: "",
    modules: [],
  },
};

const setApplicationDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPLICATION_DETAILS":
      return {
        ...state,
        applicationDetails: action.payload,
      };
    default:
      return state;
  }
};

export default setApplicationDetails;
