import React, { useEffect } from "react";
import AllapplicationsComponents from "./pageComponents/AllapplicationsComponents";
import AlledgesComponents from "./pageComponents/AlledgesComponents";
import ApplicationSummary from "../components/ApplicationSummary/ApplicationSummary";
import Sidebar from "../components/SideBar/Sidebar";

import { useDispatch, useSelector } from "react-redux";
import { setEdges, setEdgesMetrics } from "../reduxStore/actions";
import { apiEndPoints } from "../constants/ApiEndPoints";
import axios from "axios";
import { Grid, Box } from "grommet/components";

function Alledges() {
  const dispatch = useDispatch();
  // const access_token = localStorage.getItem("access_token");
  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);

  // useEffect(() => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: apiEndPoints.FETCH_ALL_EDGED_APP_METRICS,
  //     headers: {
  //       // Authorization: `Bearer ${access_token}`,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       dispatch(setEdgesMetrics({ payload: response.data.edgeDetails }));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  return (
    <section className="row-flex" style={{}}>
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <Grid
          rows={["fit-content", "49%"]}
          columns={["92.5vw"]}
          gap="small"
          ////// [a,b] are the placements cell where a=> Going_Side and b=> going_Down
          areas={[
            { name: "applicationSummary", start: [0, 0], end: [0, 1] },
            { name: "alledgesComponent", start: [0, 1], end: [0, 1] },
          ]}
        >
          <Box
            className="gridContainer"
            // style={{ height: "30vh" }}
            gridArea="applicationSummary"
          >
            <ApplicationSummary />
          </Box>

          <Box
            className="gridContainer"
            style={{
              height: `${isSummaryVisible ? "52vh" : "80vh"}`,
              transition: "all 0.3s ease 0s",
            }}
            gridArea="alledgesComponent"
          >
            <AlledgesComponents />
          </Box>
        </Grid>
      </div>
    </section>
  );
}

export default Alledges;
