import React, { useEffect, useState } from "react";
import "./Widget.css";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import Heading from "../SectionHeading/Heading";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { setSummaryVisible } from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";

function Widget() {
  const AllApplicationSummary = useSelector(
    (state) => state.setEdgeStatus.data
  );
  const isLoading = useSelector((state) => state.setEdgeStatus.isLoading);
  const [chartsLoad, setChartLoad] = useState(true);
  const [cpuPodData, setCpuPodData] = useState([]);
  const [ramPodData, setRamPodData] = useState([]);

  const [TotalApplicationStats_cpu, SetTotalApplicationStats_cpu] = useState(
    []
  );
  const [TotalApplicationStats_ram, SetTotalApplicationStats_ram] = useState(
    []
  );

  const [showStat, setShowStat] = useState(false);
  const dispatch = useDispatch();

  const COLORS = [
    General_color.success,
    General_color.warning,
    General_color.error,
    General_color.info,
  ];
  useEffect(() => {
    const dataHere = AllApplicationSummary?.edgeServiceMetricsSummary;

    SetTotalApplicationStats_cpu([
      {
        name: "Application",
        value: dataHere?.applicationCPUTotal,
      },
      { name: "Available", value: dataHere?.availableCPU },
      { name: "Others", value: dataHere?.othersCPU },
      { name: "Platform", value: dataHere?.platformCPUTotal },
    ]);

    SetTotalApplicationStats_ram([
      { name: " Application", value: dataHere?.applicationRamTotal },
      { name: "Available", value: dataHere?.availableRAM },
      { name: "Others", value: dataHere?.othersRAM },
      { name: "Platform", value: dataHere?.platformRamTotal },
    ]);

    const finalData =
      AllApplicationSummary?.edgeServiceMetricsSummary
        ?.edgeApplicationPodMetrics;
    if (finalData && finalData.length > 0) {
      const filteredPods = finalData.filter((app) => app.isPlatform == true);
      let sudoObject = [];
      let sudoRamObject = [];

      if (filteredPods) {
        setChartLoad(false);
        filteredPods.map((pod) => {
          sudoObject.push({
            name: pod.applicationName,
            value: pod.applicationCPU,
          });
          sudoRamObject.push({
            name: pod.applicationName,
            value: pod.applicationRAM,
          });
        });

        setCpuPodData(sudoObject);
        setRamPodData(sudoRamObject);
        // console.log(sudoObject, sudoRamObject)
      }
    }
  }, [AllApplicationSummary]);

  const generatePod_PieChart = (statusData) => {
    return (
      <PieChart width={168} height={168}>
        <Pie
          data={statusData}
          cx={80}
          cy={80}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          // paddingAngle={0}
          dataKey="value"
        >
          {statusData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  const generateStats = (statData) => {
    return statData.map((stat, index) => (
      <div className="eachStat">
        <span
          className="colorReceptor"
          style={{ backgroundColor: `${COLORS[index % COLORS.length]}` }}
        ></span>
        <span className="legendText">
          <span>{`${stat?.name} service`}</span>
          {/* <br /> */}

          <span>{`${stat?.value?.toFixed(2)}`}</span>
        </span>
      </div>
    ));
  };

  return (
    <>
      <div
        className="App podSummaryApp"
        style={
          showStat
            ? {
                height: "230px",
                borderBottom: "1px solid #e2e2e2",

                top: "0px",
              }
            : {
                height: "35px",
                borderBottom: "1px solid #e2e2e2",

                top: "0px",
              }
        }
      >
        {/* <section
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background:'transparent'
          }}
        > */}
        <span
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "10px",
            width: "98%",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          {/* <Heading text={`${platformNAME} Instance Summary (${appCount}/${appCount})`} /> */}

          <span>
            <Heading text={`Platform`} />{" "}
            {isLoading ||
              (chartsLoad && <SpinnerLoader style={{ paddingLeft: "10px" }} />)}
          </span>

          {/* <div
            onClick={() => {
              setShowStat(!showStat);
              dispatch(setSummaryVisible({ payload: !showStat }));
            }}
          >
            {showStat ? (
              <BiChevronUp
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                fontSize={"27px"}
              />
            ) : (
              <BiChevronDown
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                fontSize={"27px"}
              />
            )}
          </div> */}
        </span>
        {/* </section> */}

        {!chartsLoad &&
          !isLoading &&
          // ?.edgeApplicationPodMetrics?.[0]?.edgePodStatisticsList?.length >
          AllApplicationSummary?.edgeServiceMetricsSummary
            ?.edgeApplicationPodMetrics?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // columnGap: "20px",
                marginTop: "8px",
              }}
            >
              <div className="leftPieChart">
                <div className="piechartContainer">
                  <div
                    style={{
                      height: "160px",
                      width: "210px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {/* <span className="chartHeading">  {platformNAME} Status</span> */}

                    <span
                      className="chartHeading"
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        scale: "0.8",
                      }}
                    >
                      {" "}
                      CPU <br />
                      <b
                        style={{ fontSize: "12px" }}
                      >{`${TotalApplicationStats_cpu?.[3]?.value?.toFixed(
                        2
                      )} /  
                   ${AllApplicationSummary?.edgeServiceMetricsSummary?.nodeCPU?.toFixed(
                     2
                   )} `}</b>
                    </span>
                    <div
                      style={{
                        position: "relative",
                        height: "168x",
                        width: "168px",
                        // top: "-9px",
                        // left: "-10px",
                        scale: "0.8",
                      }}
                    >
                      {generatePod_PieChart(cpuPodData)}
                      {/* <span
                    style={{
                      position: "absolute",
                      top: "42%",
                      left: "45%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>{appCount}</span>
                    <span style={{ fontSize: "12px" }}>total</span>
                  </span> */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      paddingRight: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "ABBVoice-medium",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      CPU Utilization %
                    </span>
                    <div
                      className="statWrapper scaleDown"
                      style={{ marginTop: "10px" }}
                    >
                      {generateStats(cpuPodData)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #e5e5e5",
                }}
              ></div>
              <div className="rightPieChart">
                <div className="piechartContainer">
                  <div
                    style={{
                      height: "160px",
                      width: "210px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {/* <span className="chartHeading">{platformNAME} Status</span> */}
                    <span
                      className="chartHeading"
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        scale: "0.8",
                      }}
                    >
                      {" "}
                      RAM <br />{" "}
                      <b style={{ fontSize: "12px" }}>
                        {`${TotalApplicationStats_ram?.[3]?.value?.toFixed(
                          2
                        )} / 
                       
                       ${AllApplicationSummary?.edgeServiceMetricsSummary?.nodeRAM?.toFixed(
                         2
                       )}`}{" "}
                      </b>
                    </span>

                    <div
                      style={{
                        position: "relative",
                        height: "168px",
                        width: "168px",
                        // top: "-9px",
                        // left: "-10px",
                        scale: "0.8",
                      }}
                    >
                      {generatePod_PieChart(ramPodData)}

                      {/* <span
                    style={{
                      position: "absolute",
                      top: "42%",
                      left: "45%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>{appCount}</span>
                    <span style={{ fontSize: "12px" }}>total</span>
                  </span> */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      paddingRight: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "ABBVoice-medium",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      RAM Utilization GB
                    </span>
                    <div
                      className="statWrapper scaleDown"
                      style={{ marginTop: "10px" }}
                    >
                      {generateStats(ramPodData)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isLoading &&
          AllApplicationSummary?.edgeServiceMetricsSummary
            ?.edgeApplicationPodMetrics?.length == 0 && <NoDataComponent />}

        {AllApplicationSummary?.edgeServiceMetricsSummary
          ?.edgeApplicationPodMetrics == null &&
          !isLoading && <NoDataComponent />}
      </div>
    </>
  );
}

export default Widget;
