const initialState = { graphType: "", data: {}, isLoading: true };

const setResourceMonitorGraph = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESOURCE_MONITOR_GRAPH":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setResourceMonitorGraph;
