const initialState = false;

const showEdgeSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "SHOW_EDGE_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default showEdgeSettings;