export let GeneralConstants = {
  // apiReloadTime:30000,                //30 seconds
  apiReloadTime: 300000, //5 minutes

  progresConstant: {
    0: "Starting Process",
    1: "Deployment Initiated",
    2: "Configuration Ingested",
    3: "Modifications Applied",
    4: "Platform Modules Activated",
    5: "Platform Initialization Completed",
  },

  appProgresConstant: {
    0: "Starting Process",
    1: "Deployment Initiated",
    2: "Configuration Ingested",
    3: "Finished",
  },
};

export const General_color = {
  success: "#21A67A",
  error: "#EF3934",
  warning: "#FFA200",
  info: "#0052FF",
  disable: "#d9d9d9",
};


