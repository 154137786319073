
const initialState = false;

const isUnsavedEdgeSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "IS_UNSAVED_EDGE_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default isUnsavedEdgeSettings;