import React, { useState } from "react";
import { Col, Divider, Row } from "antd";

function AddToken() {
  const [tokenValue, setTokenValue] = useState("");
  return (
    <>
      {/* <br />
      <textarea type="text" onChange={(e) => setTokenValue(e.target.value)} value={tokenValue}/>
      <br />
      <button onClick={() => {
        localStorage.setItem("access_token", tokenValue);
          setTokenValue('');
   }}
      disabled={tokenValue==""?true:false}
    >
        Save Token
      </button> */}

     
      <Row style={{ gridGap: "10px" }}>
        <Col flex="1 1 300px" style={{ display:'grid', Gap: "10px"}}>
          <Row order={2} style={{ background: "red",  borderRadius:'10px', padding:'30px',height:'45vh' }}>
            <Col>Details</Col>
          </Row>
          <Row order={1} style={{ background: "pink",  borderRadius:'10px', padding:'30px',height:'45vh'  }}>
            <Col>Event log</Col>
          </Row>
        </Col>

        <Col flex="1 1 50%" style={{ columnGap: "10px", background: "pink", display:'grid', rowGap:'10px' }}>
          <Row style={{ gridGap: "10px" }}>
            <Col flex="1" style={{ background: "magenta", borderRadius:'10px', padding:'30px', height:'10vh' }}>
              status
            </Col>
          </Row>
          <Row style={{ gridGap: "10px" }}>
            <Col flex="1" style={{ background: "magenta", borderRadius:'10px', padding:'30px', height:'34vh' }}>
              details
            </Col>
          </Row>
          <Row style={{ gridGap: "10px" }}>
            <Col flex="1" style={{ background: "blue",  borderRadius:'10px', padding:'90px', height:'45vh' }}>
              Table
            </Col>
          </Row>
        </Col>

        <Col flex="1 1 300px" style={{ background: "red",  borderRadius:'10px', padding:'30px' }}>
          <Row>
            <Col>Event log</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AddToken;
