import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import DashboardComponents from "./pageComponents/DashboardComponents";
import { useDispatch, useSelector } from "react-redux";
import { setApiReloader } from "../reduxStore/actions";
import { GeneralConstants } from "../constants/GeneralConstants";

function Dashboard() {
  const dispatch = useDispatch();
  const AllEdges = useSelector((state) => state.setEdgesMetrics.data);
  // const access_token = localStorage.getItem("access_token");
  const [all_edges, setAllEdges] = useState([]);
  const [all_apps, setAllApps] = useState([]);
  const apiReloadTime = GeneralConstants.apiReloadTime;

  useEffect(() => {
    dispatch(setApiReloader({ payload: 0 }));
  }, []);

  const apiReloader = useSelector((state) => state.setApiReloader);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setApiReloader({ payload: apiReloader + 1 }));
    }, apiReloadTime);
  }, [apiReloader]);
  let allApp = 0;
  let apps_in_use = 0;
  allApp = all_apps?.appsAvailable;
  apps_in_use = all_apps?.appsInUse;

  const dashboardInventoryData = [
    {
      name: `${all_edges.length}`,
      route: "/alledges",
    },
    {
      name: `${allApp}`,
      details: `${apps_in_use} application(s) in use`,
      // warningstatus: [
      //   { icon: "Installed", value: `${totalInstalledApps}` },
      //   { icon: "medium", value: `${totalMediumApps}` },
      //   { icon: "critical", value: `${totalCritialApps}` },
      // ],
      route: "/alltenantapps",
    },
  ];

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <DashboardComponents
          inventoryData={dashboardInventoryData}
          inventoryLoading={all_edges.length == 0 ? true : false}
        />
      </div>
    </section>
  );
}

export default Dashboard;
