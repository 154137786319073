const initialState = { data: {}, isLoading: true };

const setEdgeAppDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EDGE_APP_DETAILS":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setEdgeAppDetails;
