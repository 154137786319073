import React from 'react'
import Widget from './Widget'

function index({data, inventoryLoading}) {
  return (
    // <Widget data={data} inventoryLoading={inventoryLoading} />
    <Widget data={data}/>
  )
}

export default index