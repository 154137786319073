import React, { useDebugValue, useEffect } from "react";
import Widget from "./Widget";

function AppSettings({ settingData, hidepopup, containerid }) {
  return (
    <Widget
      settingData={settingData}
      hidepopup={hidepopup}
      containerid={containerid}
    />
  );
}

export default AppSettings;
