import React from "react";

function NoDataComponent({message}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
        fontFamily: "ABBVoice-medium",
        color: "#d2d2d2",
        fontSize:'18px'
      }}
    >
      {message?message:"No data at the moment"}
     
    </div>
  );
}

export default NoDataComponent;
