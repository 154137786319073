import React from 'react'
import Sidebar from '../components/SideBar/Sidebar'
import AppDetailsComponent from './pageComponents/AppDetailsComponent'
import { useParams } from 'react-router'

function AppDetailPage() {
  // const {settingData} = useParams();
  const queryParam = new URLSearchParams(window.location.search)
  const settingData = queryParam.get("settingData")
  const _settingData = JSON.parse(settingData);

  return (
    <section className="row-flex">
    <div  className="sidebarContainer">
      <Sidebar />
    </div>
    <div className="MainContainer">
      <AppDetailsComponent settingData={_settingData}/>
    </div>
  </section>
  )
}

export default AppDetailPage