const initialState = {"data":[],"isLoading":true};

const setGraph = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_GRAPH":
            return state = action.payload
        default:
            return state;
    }
}

export default setGraph;