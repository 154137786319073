const initialState = [];

const setInstalledApps = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INSTALLEDAPPS":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setInstalledApps;
