const initialState = false;

const confirmDialouge = (state= initialState, action) =>{
    switch (action.type) {
        case "CONFIRM_DIALOUGE":
            return state = action.payload
        default:
            return state;
    }
}

export default confirmDialouge;