import React from 'react'
import Widget from './Widget'

function MethodInvocation({data}) {
  return (
    // <Widget data={data} inventoryLoading={inventoryLoading} />
    <Widget data={data}/>
  )
}

export default MethodInvocation