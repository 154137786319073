import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Text } from "recharts";
import "./Widget.css";
import Heading from "../SectionHeading/Heading";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setSummaryVisible } from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";
import { Tooltip } from "antd";

function Widget() {
  const [showStat, setShowStat] = useState(true);
  const [pathname, setPathname] = useState("");
  const [platformNAME, setPlatformNAME] = useState("");
  const [appCount, setAppCount] = useState(0);

  const AllAvailableApps = useSelector((state) => state.setInstalledApps);
  const Alledges = useSelector((state) => state.setEdgesMetrics.data);
  const isLoading = useSelector((state) => state.setEdgesMetrics.isLoading);

  const dispatch = useDispatch();

  const [cpu_data, setCpuData] = useState([]);
  const [ram_data, setRamData] = useState([]);

  useEffect(() => {
    setPathname(window.location.pathname);
    if (
      pathname.includes("/allapplications") ||
      pathname.includes("/alltenantapps")
    ) {
      setPlatformNAME("Application");
      setAppCount(AllAvailableApps?.length);
    } else {
      setPlatformNAME("Edge");
      setAppCount(Alledges?.length);
    }

    if (Alledges?.length > 0) {
      let green_cpu = 0,
        yellow_cpu = 0,
        red_cpu = 0,
        totalGreeCpu = 0,
        totalYellowCpu = 0,
        totalRedCpu = 0,
        green_ram = 0,
        yellow_ram = 0,
        red_ram = 0,
        totalGreenRam = 0,
        totalYellowRam = 0,
        totalRedRam = 0,
        totalNoDataCpu = 0,
        totalNoDataRam = 0;

      Alledges.forEach((e) => {
        if (!e?.edgeMetrics?.length) {
          totalNoDataCpu += 1;
          totalNoDataRam += 1;
          return;
          
        }

        const cpuUsage = e?.edgeMetrics?.[0]?.data?.[0]?.v;
        const ramUsage = e?.edgeMetrics?.[1]?.data?.[0]?.v;

        if (cpuUsage === undefined) {
          totalNoDataCpu += 1;
        } else {
          if (cpuUsage >= 0 && cpuUsage <= 50) {
            totalGreeCpu += 1;
            green_cpu += cpuUsage;
          } else if (cpuUsage >= 51 && cpuUsage <= 80) {
            totalYellowCpu += 1;
            yellow_cpu += cpuUsage;
          } else if (cpuUsage >= 81 && cpuUsage <= 100) {
            totalRedCpu += 1;
            red_cpu += cpuUsage;
          }
        }

        if (ramUsage === undefined) {
          totalNoDataRam += 1;
        } else {
          if (ramUsage >= 0 && ramUsage <= 50) {
            totalGreenRam += 1;
            green_ram += ramUsage;
          } else if (ramUsage >= 51 && ramUsage <= 80) {
            totalYellowRam += 1;
            yellow_ram += ramUsage;
          } else if (ramUsage >= 81) {
            totalRedRam += 1;
            red_ram += ramUsage;
          }
        }
      });

      setCpuData([
        {
          name: "green",
          value: totalGreeCpu,
          number: totalGreeCpu,
          range: "0 - 50%",
        },
        {
          name: "yellow",
          value: totalYellowCpu,
          number: totalYellowCpu,
          range: "51 - 80%",
        },
        {
          name: "red",
          value: totalRedCpu,
          number: totalRedCpu,
          range: "81 - 100%",
        },
        {
          name: "no_data",
          value: totalNoDataCpu,
          number: totalNoDataCpu,
          range: "No Data",
        },
      ]);

      setRamData([
        {
          name: "green",
          value: totalGreenRam,
          number: totalGreenRam,
          range: "0 - 50%",
        },
        {
          name: "yellow",
          value: totalYellowRam,
          number: totalYellowRam,
          range: "51 - 80%",
        },
        {
          name: "red",
          value: totalRedRam,
          number: totalRedRam,
          range: "> 80%",
        },
        {
          name: "no_data",
          value: totalNoDataRam,
          number: totalNoDataRam,
          range: "No Data",
        },
      ]);
    }
  }, [Alledges]);

  const COLORS = [
    General_color.success,
    General_color.warning,
    General_color.error,
    "#bababa",
  ];

  const generateStats = (statData) => {
    return statData.map((stat, index) => (
      <div
        className="eachStat"
        style={{ width: "auto", fontSize: "unset" }}
        key={stat.name}
      >
        <span
          className="colorReceptor"
          style={{ backgroundColor: `${COLORS[index % COLORS.length]}` }}
        ></span>
        <span className="legendText">
          <span>{stat?.range}</span>
        </span>
      </div>
    ));
  };

  const generatePieChart = (statusData) => {
    const renderLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      value,
      number,
      index,
    }) => {
      if (number === 0) return null;
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <Tooltip title={`${number} Edge(s)`}>
          <Text
            x={x}
            y={y}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={15}
          >
            {number}
          </Text>
        </Tooltip>
      );
    };

    const total = statusData.reduce((acc, data) => acc + data.value, 0);

    const minThreshold = 7;
    const minPercentage = (minThreshold / total) * 100;

    const normalizedData = statusData.map((data) => {
      const normalizedValue = (data.value / total) * 100;
      return {
        ...data,
        value:
          normalizedValue < minPercentage && data.value > 0
            ? minPercentage
            : normalizedValue,
      };
    });

    return (
      <PieChart width={200} height={200}>
        <Pie
          data={normalizedData}
          cx={100}
          cy={100}
          innerRadius={50}
          outerRadius={90}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          label={renderLabel}
        >
          {normalizedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  return (
    <div
      className="App"
      style={
        showStat
          ? {
              height: "215px",
              borderBottom: "1px solid #f0f0f0",
              padding: "0px",
            }
          : {
              height: "40px",
              borderBottom: "1px solid #f0f0f0",
              padding: "0px",
            }
      }
    >
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          borderBottom: "1px solid #f0f0f0",
          padding: "10px",
          height: "20px",
        }}
      >
        <span>
          <Heading text={`Summary`} /> {isLoading && <SpinnerLoader />}
        </span>
        <div
          onClick={() => {
            setShowStat(!showStat);
            dispatch(setSummaryVisible({ payload: !showStat }));
          }}
        >
          {showStat ? (
            <BiChevronUp
              style={{
                cursor: "pointer",
                marginTop: "10px",
              }}
              fontSize={"27px"}
            />
          ) : (
            <BiChevronDown
              style={{
                cursor: "pointer",
                marginTop: "10px",
              }}
              fontSize={"27px"}
            />
          )}
        </div>
      </section>
      {Alledges?.length > 0 && !isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "20px",
            marginTop: "8px",
          }}
        >
          <div
            className="leftPieChart"
            style={{ borderRight: "1px solid #f0f0f0" }}
          >
            {cpu_data?.[0]?.value == 0 &&
            cpu_data?.[1]?.value == 0 &&
            cpu_data?.[2]?.value == 0 ? (
              <div
                style={{
                  height: "155px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoDataComponent message="No CPU data at the moment" />
              </div>
            ) : (
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <span className="chartHeading" style={{ fontSize: "15px" }}>
                    {" "}
                    <b>{Alledges?.length}</b>{" Edges"}
                  </span>
                  <div
                    style={{
                      position: "relative",
                      height: "200px",
                      width: "207px",
                      top: "-9px",
                      scale: "0.9",
                    }}
                  >
                    {generatePieChart(cpu_data)}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <span
                    style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}
                  >
                    {" "}
                    CPU Utilization
                  </span>
                  <div className="statWrapper">{generateStats(cpu_data)}</div>
                </div>
              </div>
            )}
          </div>

          <div className="rightPieChart">
            {ram_data?.[0]?.value == 0 &&
            ram_data?.[1]?.value == 0 &&
            ram_data?.[2]?.value == 0 ? (
              <div
                style={{
                  height: "155px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoDataComponent message="No RAM data at the moment" />
              </div>
            ) : (
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <span className="chartHeading" style={{ fontSize: "15px" }}>
                    {" "}
                    <b>{Alledges?.length}</b>{" Edges"}
                  </span>
                  <div
                    style={{
                      position: "relative",
                      height: "200px",
                      width: "207px",
                      top: "-9px",
                      scale: "0.9",
                    }}
                  >
                    {generatePieChart(ram_data)}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <span
                    style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}
                  >
                    {" "}
                    RAM Utilization
                  </span>
                  <div className="statWrapper">{generateStats(ram_data)}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {Alledges?.length == 0 && !isLoading && <NoDataComponent />}
    </div>
  );
}

export default Widget;
