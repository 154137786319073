import "@abb/abb-common-ux-react/styles.css";
import "@abb/abb.ia.components.react/index.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./ErrorBoundary";
import * as axios from "./axios";
import { Grommet } from "grommet";


axios.register();

ReactDOM.render(
  <React.StrictMode>
    <Grommet>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Grommet>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
