const initialState = {type:'',payload:[]};

const setEdges = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_EDGES":
            return state = action.payload
        default:
            return state;
    }
}

export default setEdges;