import React, { useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";



import { useDispatch } from "react-redux";

import AlltenantappComponents from "./pageComponents/AlltenantappComponents";
import { Col, Row } from "antd";

function AllTenantapplications() {
  const dispatch = useDispatch();
  // const access_token = localStorage.getItem("access_token");

  // const { edgeId } = useParams();
  const queryParam = new URLSearchParams(window.location.search)
  const edgeId = queryParam.get("edgeId")
  // const edgeId = "b926a670-f55e-44a9-9e21-539e4a531bcc";
  const [dataPresent, setDataPresent] = useState(false)

  const _original_AllApplications = [
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_1",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_3",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_3",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "ABB FA Edgenius Application Asset Hub.1",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
  ];

 


 

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
   

        <Row style={{ gridGap: "10px", paddingRight:'20px' }}>
        <Col flex="1 1 300px" style={{ display:'grid', Gap: "10px",  height: '90vh'}}  className="gridContainer">
        <AlltenantappComponents loadingData={dataPresent}/>
        </Col>
      </Row>

      </div>
    </section>
  );
}

export default AllTenantapplications;
