import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";

import { IoIosAdd } from "react-icons/io";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { useDispatch, useSelector } from "react-redux";
import { setResourceMonitorGraph } from "../../reduxStore/actions";
import { Table, Form } from "antd";

import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";

import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

function ResourceMonitorApplications({ apiLoaded }) {
  // const isSummaryVisible = useSelector(state=>state.setSummaryVisible)

  const isSummaryVisible = false;
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const original_AllApplications = useSelector(
    (state) => state.setInstalledApps
  );
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  let [deleteAppForm] = Form.useForm();
  const [refactoredInstalledApp, setRefactoredInstalledApp] = useState([]);

  const [sudoState, setSudoState] = useState(0);

  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(true);

  const [open, setOpen] = useState({});

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "2px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const showHideModules = (id) => {
    let concatId = `canbehidden_${id}`;
    let parentRowId = `parentRow_${id}`;
    document.getElementById(`add_${id}`).classList.toggle("displaynone");
    document.getElementById(`remove_${id}`).classList.toggle("displaynone");
    let allparentTd = document
      .getElementById(parentRowId)
      .querySelectorAll("td");
    for (let i = 0; i < allparentTd.length; i++) {
      allparentTd[i].classList.toggle("hiddenParentUnderline");
    }
    document.getElementById(concatId).classList.toggle("hiddenModules");
  };
  useEffect(() => {
    if (original_AllApplications.length > refactoredInstalledApp.length) {
      setView((prev) => prev + 1);
    }
    // console.log("riunned");
  });

  useEffect(() => {
    if (original_AllApplications.length === 0) {
      return;
    }

    let correctedInstalledApps = [];

    original_AllApplications.map((app, key) => {
      if (app?.applicationModuleMetricsList?.length > 0) {
        let modulesList = [];
        app?.applicationModuleMetricsList?.map((module, moduleKey) => {
          let containerList = [];

          if (module?.moduleContainersList?.length > 0) {
            module.moduleContainersList.forEach((container, containerKey) => {
              containerList.push({
                key: `${module?.displayName || module?.name}_${
                  container?.name
                }_${moduleKey}_${containerKey}_${app.model}_${key}`,
                containerName: container?.name,
                cpulimit: `${container?.resources?.limits?.cpu}`,
                memorylimit: `${container?.resources?.limits?.memory}`,
              });
            });
          } else {
            containerList.push({
              key: `${module?.displayName || module?.name}_${moduleKey}_${
                app.model
              }_${key}`,
              containerName: module?.name,
              cpulimit: `${module?.resources?.limits?.cpu}`,
              memorylimit: `${module?.resources?.limits?.memory}`,
            });
          }

          modulesList.push({
            key: `${module?.displayName || module?.name}_${moduleKey}_${
              app.model
            }_${key}`,
            name: module?.displayName || module?.name,
            cpu: `${module?.cpu}`,
            memory: `${module?.memory}`,
            status: `${module?.status}`,
            version: module?.installedVersion,
            containers: containerList,
          });
        });

        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
          children: modulesList,
          applicationObjectId: app?.applicationObjectId,
        });
      } else {
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
          applicationObjectId: app?.applicationObjectId,
        });
      }
      setRefactoredInstalledApp(correctedInstalledApps);
    });
  }, [original_AllApplications, view]);

  const columns = [
    {
      title: "Installed Application",
      dataIndex: "name",
      width: "60%",
      ellipsis: true,
      render: (text, record) => {
        if (record?.isClickable) {
          return (
            <span style={{ color: "black", cursor: "pointer" }}>{text}</span>
          );
        } else {
          return <span>{text}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   ellipsis: true,
    //   width: "10%",

    //   render: (status) =>
    //     status == undefined ? (
    //       "-"
    //     ) : (
    //       <span>
    //         {status == "Running" ? (
    //           <WarningIcons type="good" />
    //         ) : (
    //           <WarningIcons type="medium" />
    //         )}
    //       </span>
    //     ),
    //   // responsive: ['md'],
    // },
    // {
    //   title: "CPU %",
    //   dataIndex: "cpu",
    //   ellipsis: true,
    //   render: (cpu) =>
    //     cpu != undefined ? <span>{parseFloat(cpu).toFixed(2)}</span> : "-",
    //   width: "20%",
    // },
    // {
    //   title: "Memory %",
    //   dataIndex: "memory",
    //   ellipsis: true,
    //   render: (memory) =>
    //     memory != undefined ? (
    //       <span>{parseFloat(memory).toFixed(2)}</span>
    //     ) : (
    //       "-"
    //     ),
    //   width: "20%",
    // },
    {
      title: "CPU %",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(cpu),
                value: parseFloat(cpu).toFixed(2),
              },
            ])
          : "-",
      // responsive: ['md'],
      width: "20%",
    },
    {
      title: "Memory %",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(memory),
                value: parseFloat(memory).toFixed(2),
              },
            ])
          : "-",
      width: "20%",
      // responsive: ['md'],
    },
  ];

  const GenerateTable = (applications) => {
    return (
      <Table
        columns={columns}
        dataSource={applications}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (record?.isClickable) {
                setSelectedRowKey(record.key);
                dispatch(
                  setResourceMonitorGraph({
                    payload: {
                      graphType: "applications",
                      data: record,
                      isLoading: false,
                    },
                  })
                );
              }
              // console.log({ graphType: "applications", data: record });
            },
            style: {
              backgroundColor:
                selectedRowKey === record.key ? "#e0f0ff" : "#f5f5f5",
            },
          };
        }}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            } else {
              return (
                <BiChevronRight
                  style={{
                    fontSize: "0px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            }
          },
        }}
      />
    );
  };

  const adjustHeight = () => {
    let getMainContainer = document?.getElementsByClassName("gridContainer");
    let finalHeight = getMainContainer?.[1]?.clientHeight - 100;
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          {/* <Heading text="Applications" /> */}
          {!apiLoaded && <SpinnerLoader />}
        </span>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
        <div
          className="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            // height: `${
            //   isSummaryVisible ? "calc(52vh - 12vh)" : "calc(80vh - 12vh)"
            // }`,
            height: `${customHeight}px`,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              // diplay: "contents",
              width: "100%",
              backgroundColor: "#f5f5f5",
              height: "auto",
            }}
          >
            {apiLoaded && original_AllApplications.length == 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  flex: 1,
                }}
              >
                <div style={{ margin: "100px 0px" }}>
                  <NoDataComponent message="No apps installed" />
                </div>
              </div>
            )}

            {original_AllApplications.length > 0 &&
              apiLoaded &&
              GenerateTable(refactoredInstalledApp)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceMonitorApplications;
