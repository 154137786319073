import React, { useEffect, useState } from "react";

import { Input, Radio, Button, Form, message, Alert } from "antd";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import {
  setIsEdgeDeploying,
  setBasicEdgeSettings,
  showEdgeSettings,
  setPageNotifications,
  isUnsavedEdgeSettings,
 
} from "../../reduxStore/actions";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import {
  GeneralConstants,
  General_color,

} from "../../constants/GeneralConstants";

const COD = "connectondemand";

function Widget() {
  const dispatch = useDispatch();
  // const { edgeId, platformType } = useParams();
 
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const platformType = useSelector(
    (state) => state.setEdgeDetails?.data?.[4]?.detail
  );

  const data = useSelector((state) => state.setBasicEdgeSettings);
  let detailsData = useSelector((state) => state.setEdgeDetails);

  let nameEdge = data?.properties?.[0]?.value;
  let locationEdge = data?.properties?.[1]?.value;
  let baseURL = data?.properties?.[3]?.value;
  useEffect(() => {
    setEdgeName(nameEdge);
    setLocation(locationEdge);
    setEdgeBaseURL(baseURL);
    setSudState((prev) => prev + 1);
  }, [nameEdge, locationEdge, baseURL, data]);

  const [platformServices, setPlatformServices] = useState([
    {
      name: "Standalone",
      // name: "ABB PA Edgenius Hub",
      typeIdService:
        "abb.pa.edgenius.platform.services.connectivity.Standalone",
      tags: ["connectondemand"],
    },
    {
      name: "Child",
      // name: "ABB PA Edgenius Hub",
      typeIdService: "abb.pa.edgenius.platform.services.connectivity.hub",
      tags: ["connectondemand"],
    },
    {
      // name: "ABB PA Edgenius Publisher",
      name: "Parent",
      typeIdService: "abb.pa.edgenius.platform.services.connectivity.publisher",
      tags: ["connectondemand"],
    },
    {
      // name: "ABB PA Edgenius Publisher",
      name: "Bridge",
      typeIdService: "null",
      tags: ["connectondemand"],
    },
  ]);
  const [edgeName, setEdgeName] = useState("");
  const [location, setLocation] = useState("");
  const [edgeBaseURL, setEdgeBaseURL] = useState("");
  const [_platformType, setPlatformType] = useState(platformType);
  const [publisherValue, setPublisherValue] = useState(
    platformServices[0].typeIdService
  );

  const [restrictionMessage, setRestrictionMessage] = useState("");
  const handleHostNameChange = (e) => {
    const value = e.target.value;
    if (value.includes(" ") || value.includes("/") || value.includes("\\")) {
      setRestrictionMessage(
        "Spaces, forward slashes, and backslashes are not allowed."
      );
    } else {
      setRestrictionMessage("");
      setEdgeBaseURL(value);
      setIsChanged(true);
    }
  };

  let edgeRole = useSelector(
    (state) => state.setEdgeDetails?.data?.[3]?.detail
  );
  let intialOfRole = `abb.pa.edgenius.platform.services.connectivity.`;

  useEffect(() => {
    if (edgeRole == "Parent") {
      edgeRole = "publisher";
    }
    if (edgeRole == "hub") {
      edgeRole = "child";
    }
    setPublisherValue(`${intialOfRole}${edgeRole}`);
    console.log("edgeRole", `${intialOfRole}${edgeRole}`);
  }, [edgeRole]);

  const [isSaving, setIsSaving] = useState(false);
  const [ischanged, setIsChanged] = useState(false);
  let changedValues = [];
  let payload = {};

  const collectChangeValues = () => {
    let foundIndex = platformServices.findIndex(
      (item) => item.typeIdService === publisherValue
    );
    let newPlatformService = {};
    if (foundIndex != -1) {
      newPlatformService = platformServices[foundIndex];
    }

    let payload = [
      {
        sectionName: data.sectionName,
        parentTypeId: data.parentTypeId.split("/")[1],
        properties: [
          {
            value: edgeName,
            targets: data.properties[0].targets,
          },
          {
            value: location,
            targets: data.properties[1].targets,
          },
          {
            value: COD,
            targets: data.properties[2].targets,
            platformServices: [newPlatformService],
          },
          {
            value: edgeBaseURL,
            targets: data.properties[3].targets,
          },
        ],
      },
    ];

    return payload;
  };

  const [sudoState, setSudState] = useState(0);

  const handleConnectivityModeChange = (e) => {
    setPlatformType(e.target.value);
    setIsChanged(true);
  };

  const setPublisher = (e) => {
    setIsChanged(true);

    setPublisherValue(e.target.value);
  };

  const toggleCheckbox = (label, tag) => {
    let correctDataIndex = 0;
    let correctData = [];
    let platformServices_copy = platformServices;

    platformServices_copy.map((service, i) => {
      if (service.name === label) {
        correctDataIndex = i;
        correctData = service;
        return;
      }
    });

    let gotdata = correctData.tags.indexOf(tag);

    if (gotdata == -1) {
      correctData.tags.push(_platformType);
    } else {
      correctData.tags.splice(gotdata, 1);
    }

    platformServices_copy[correctDataIndex] = correctData;

    setPlatformServices(platformServices_copy);
    setSudState((prev) => prev + 1);
  };

  let notiMsg;
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications?.newData?.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications?.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const handleSave = () => {
    // Handle saving settings
    // Generate GUID
    const NewMainData = data;
    function CreateGuid() {
      function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
      }
      return _p8() + _p8(true) + _p8(true) + _p8();
    }

    const guid = CreateGuid();
    localStorage.setItem("correlationId", guid);

    setIsSaving(true);
    if (platformType != COD) {
      dispatch(showEdgeSettings({ payload: false }));
      dispatch(setIsEdgeDeploying({ payload: true }));
      // dispatch(confirmDialouge({ payload: true }));
      let tempIdToRemove = `edgeSettingUpdate_${CreateGuid()}`;
      localStorage.setItem("tempIdToRemove", tempIdToRemove);
      AddEdgeSettingsNotification(
        `${NewMainData.sectionName} applied successfully`,
        `${tempIdToRemove}`,

        {
          edgeId: edgeId,
          isUpdate: false,
          isEdge: true,
          modalKey: `edgeSettingUpdate_${CreateGuid()}`,
          setting_type: NewMainData.parentTypeId.split("/")[1],
          settingSectionName: NewMainData.sectionName,
          edgeName: detailsData.data[0].detail,
          successMessage: `${NewMainData.sectionName} applied successfully`,
          time: generateTime(),
          isComplete: false,
          limit: 5,
          currStateValue: 0,
          icon: "good",
        }
      );
    }

    let edgeConfiguration = JSON.stringify(collectChangeValues());

    // console.log(edgeConfiguration);

    let postEdgeSettingsURL = apiEndPoints.SET_BASIC_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    postEdgeSettingsURL = postEdgeSettingsURL.replace(
      "*correlationId*",
      localStorage.getItem("correlationId")
    );

    let putEdgeSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: platformType == COD ? "put" : "post",
      maxBodyLength: Infinity,
      url: platformType == COD ? putEdgeSettingsURL : postEdgeSettingsURL,
      headers:{
       
      },
      data: edgeConfiguration,
    };
    
    axios
      .request(config)
      .then((response) => {
        changedValues.map((prop) => {
          let indextoupdate = NewMainData.properties.findIndex(
            (item) => item.targets == prop.targets
          );

          if (indextoupdate != -1) {
            NewMainData.properties[indextoupdate].value = prop.value;
          }
        });

        dispatch(setBasicEdgeSettings({ payload: NewMainData }));
        detailsData.data[0].detail = response.data[0].properties[0].value; //Edge Name
        detailsData.data[2].detail = response.data[0].properties[1].value; //Edge Location
        detailsData.data[3].detail =
          response.data[0].properties[2].platformServices[0].name; // Edge Role

        localStorage.setItem("edgeName", detailsData.data[0].detail);
        // console.log(localStorage.getItem("edgeName"));

        setIsSaving(false);

        setIsChanged(false);
        if (platformType == COD) {
          AddEdgeSettingsNotification(
            `${NewMainData.sectionName} applied successfully`,
            `edgeSettingUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `edgeSettingUpdate_${CreateGuid()}`,
              setting_type: NewMainData.parentTypeId.split("/")[1],
              settingSectionName: NewMainData.sectionName,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${NewMainData.sectionName} applied successfully`,
              time: generateTime(),
              isComplete: false,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        notiMsg = "Applying basic edge settings failed, please try again";
        message.error(notiMsg);

        if (platformType !== COD) {
          let notiToRemoveId = localStorage.getItem("tempIdToRemove");
          if (notiToRemoveId !== null) {
            oldNotifications.newData.filter(
              (ele, i) => ele.id !== notiToRemoveId
            );
            oldNotifications.isNew = false;
            sessionStorage.setItem(
              "sessionNotification",
              JSON.stringify(oldNotifications)
            );
          }
        }

        AddEdgeSettingsNotification(
          `Applying basic edge settings failed, please try again`,
          `edgeSettingUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `edgeSettingUpdate_${CreateGuid()}`,
            // setting_type: NewMainData.parentTypeId.split("/")[1],
            // settingSectionName: NewMainData.sectionName,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `Applying basic edge settings failed, please try again`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );

        dispatch(setIsEdgeDeploying({ payload: false }));
      });
  };
  const reloadForm = () => {
    setPlatformType(platformType);
    setPublisherValue(platformServices[0].typeIdService);
    setEdgeName(nameEdge);
    setLocation(locationEdge);
    setEdgeBaseURL(baseURL);
    setIsChanged(false);
  };

  const checkIsChanged = () => {
    if (
      edgeName === data?.properties?.[0]?.value &&
      location === data?.properties?.[1]?.value
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  useEffect(() => {
    checkIsChanged();
  }, [sudoState]);

  const trueFalse = () => {
    if (
      `${edgeName}` &&
      `${location}` &&
      COD == _platformType &&
      `${edgeBaseURL}` &&
      `${publisherValue}` &&
      ischanged
    ) {
      dispatch(isUnsavedEdgeSettings({ payload: true }));
      return false;
    } else {
      dispatch(isUnsavedEdgeSettings({ payload: false }));

      return true;
    }
  };

  return (
    <section className="App">
      <div className="edge-settings-container">
        <div className="edge-settings-body">
          <div className="edge-settings-content">
            {/* <h2 style={{fontFamily:'ABBvoice-medium'}}>{data.sectionName}</h2>

            <hr className="horizontal-line" /> */}
            <div className="settings-section">
              {/* <h2 className="settings-section-title">{data.sectionName}</h2> */}

              <div className="settings-form">
                {data.properties && (
                  <Form layout="vertical" onFinish={handleSave}>
                    {data.properties.map(
                      (property, i) =>
                        property.presentationType === "Text" && (
                          <>
                            {property.targets ==
                            "edge.properties.ingress.host.name" ? (
                              ""
                            ) : (
                              <Form.Item
                                class="text_1"
                                label={property.name}
                                style={{
                                  marginBottom: "10px",
                                  fontWeight: 500,
                                }}
                                // name={
                                //   property?.name == "Name" ? "Name" : "Location"
                                // }
                                // rules={[{ required: true }]}
                              >
                                <Input
                                  required
                                  size="sm"
                                  value={
                                    property?.name == "Name"
                                      ? edgeName
                                      : location
                                  }
                                  onChange={(e) => {
                                    property?.name == "Name"
                                      ? setEdgeName(e.target.value)
                                      : setLocation(e.target.value);
                                    setSudState((prev) => prev + 1);
                                  }}
                                />
                              </Form.Item>
                            )}
                          </>
                        )
                    )}
                    {/* <Form.Item label="test" name="test" rules={[{ required: true }]} >
                              <Input/>
                            </Form.Item> */}
                    <Form.Item
                      class="text_1"
                      label="Connectivity Mode"
                      style={{ marginBottom: "10px", fontWeight: 500 }}
                    >
                      <div className="radio-group">
                        <Radio.Group
                          // orientation="horizontal"
                          className="platformtype-radiobuttons"
                          // sizeClass="large"
                          value={_platformType}
                          onChange={handleConnectivityModeChange}
                          // onChange={(e) =>
                          //   collectChangeValues(e.target.value)
                          // }
                          style={{
                            marginBottom: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          {data.properties &&
                            data.properties
                              .filter(
                                (property) =>
                                  property.name === "Connectivity Mode"
                              )
                              .map((property) => (
                                <Radio value={COD}>Connect on demand</Radio>
                              ))}
                          {data.properties &&
                            data.properties
                              .filter(
                                (property) =>
                                  property.name === "Connectivity Mode"
                              )
                              .map((property) => (
                                <Radio
                                  //key={property.value}

                                  value={"connected"}
                                  disabled
                                >
                                  Connected
                                </Radio>
                              ))}
                        </Radio.Group>
                      </div>
                    </Form.Item>

                    {COD === _platformType && (
                      <Form.Item
                        class="text_1"
                        label={`${data?.properties?.[3]?.name}`}
                        style={{
                          marginBottom: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {/* Host Name */}
                        <Input
                          size="sm"
                          value={edgeBaseURL}
                          onChange={handleHostNameChange}
                          disabled={platformType == COD ? true : false}
                        />
                        {restrictionMessage && (
                          <Alert message={restrictionMessage} type="error" />
                        )}
                      </Form.Item>
                    )}

                    <Form.Item
                      label="Edge Role"
                      class="text_1"
                      style={{ marginBottom: "10px", fontWeight: 500 }}
                    >
                      <div className="radio-group">
                        <Radio.Group
                          value={publisherValue}
                          onChange={setPublisher}
                          // onChange={(e) =>
                          //   collectChangeValues(e.target.value)
                          // }

                          style={{
                            display: "flex",
                            fontWeight: "normal",
                          }}
                        >
                          {
                            // _platformType === COD &&
                            platformServices.map((service, key) => (
                              <div className="form-field" key={key}>
                                <Radio
                                  value={`${service.typeIdService}`}
                                  disabled={
                                    `${service.typeIdService}` == "null" ||
                                    platformType == COD
                                      ? true
                                      : false
                                  }
                                >{`${service.name}`}</Radio>
                              </div>
                            ))
                          }
                        </Radio.Group>
                      </div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "10px" }}>
                      <div
                        className="button-group"
                        style={{
                          display: "flex",
                          columnGap: "20px",
                        }}
                      >
                        <Button
                          htmlType="submit"
                          id="bc"
                          type="primary"
                          loading={isSaving}
                          // icon={<PoweroffOutlined />}
                          className="text_1"
                          style={{
                            background: trueFalse()
                              ? General_color.disable
                              : "#1f1f1f",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "31px",
                            padding: "5px 15px",
                            // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                            cursor: trueFalse() ? "not-allowed" : "pointer",
                            fontWeight: "500",
                            border: "0px",
                            color: "white",
                            marginLeft: "auto",
                            fontFamily: "ABBVoice",
                          }}
                          // onClick={handleSave}
                          disabled={trueFalse()}
                        >
                          Apply
                        </Button>
                        <Button
                          // sizeClass="medium"
                          className="text_1"
                          style={{
                            fontFamily: "ABBVoice",
                            color: trueFalse() ? General_color.disable : "#000",
                            fontWeight: "500",
                            // background:'#000',
                            padding: "0px 15px",
                            borderRadius: "100px",
                          }}
                          variant="outline"
                          onClick={reloadForm}
                          disabled={trueFalse()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Widget;
