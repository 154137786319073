import React, { useState, useEffect } from "react";
import "./Widget.css";
import Heading from "../SectionHeading/Heading";
import { useSelector } from "react-redux";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
// import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { Tooltip } from "antd";

function Widget() {
  const appModuleDetails = useSelector(
    (state) => state.setApplicationDetails.applicationDetails.modules
  );

  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    if (appModuleDetails.length > 0) {
      setSelectedModule(appModuleDetails[0].moduleName);
    }
  }, [appModuleDetails]);

  const handleModuleClick = (moduleName) => {
    setSelectedModule(moduleName);
  };

  return (
    <div className="App" style={{ padding: 0 }}>
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "96%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text="Modules" />
        {appModuleDetails.length == 0 && <SpinnerLoader />}
      </div>
      <div
        className="screenTabs"
        style={{
          padding: "0px 10px",
          marginTop: "10px",
          overflow: "auto",
          paddingBottom: "10px",
        }}
      >
        {appModuleDetails.map((module) => (
          <span
            key={module.moduleName}
            className={
              selectedModule === module.moduleName
                ? "viewOptions active"
                : "viewOptions"
            }
            onClick={() => handleModuleClick(module.moduleName)}
          >
            <span className="text_1 overFlowText">{module.moduleName}</span>
          </span>
        ))}
      </div>
      <hr style={{}} />

      {selectedModule && (
        <div style={{ marginTop: "0px" }}>
          <span
            className="heading_2"
            style={{ padding: "0px 10px", color: "#979595", fontWeight: "700" }}
          >
            Information
          </span>
          <hr style={{}} />
          {appModuleDetails.map((module) => {
            if (module.moduleName === selectedModule) {
              return (
                <div key={module.moduleName}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontFamily: "ABBvoice-bold",
                        }}
                      >
                        Id
                      </span>

                      <Tooltip
                        title={
                          module.moduleObjectId == ""
                            ? "-"
                            : module.moduleObjectId
                        }
                      >
                        <span className="text_1 overFlowText">
                          {module.moduleObjectId == ""
                            ? "-"
                            : module.moduleObjectId}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontFamily: "ABBvoice-bold",
                        }}
                      >
                        Type
                      </span>
                      <Tooltip title={module?.typeId}>
                        <span className="text_1 overFlowText">
                          {module.typeId}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontFamily: "ABBvoice-bold",
                        }}
                      >
                        Container Image(s)
                      </span>

                      {Array.isArray(module?.images) &&
                        module?.images.map((img, index) => (
                          <Tooltip key={index} title={img}>
                            <span className="text_1 overFlowText">{img}</span>
                          </Tooltip>
                        ))}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default Widget;
