import { Grid, Box } from "grommet/components";
import React, { useEffect, useState } from "react";
import Details from "../../components/Details/Details";
import Eventlog from "../../components/EventLog/Eventlog";
import AppSettings from "../../components/AppSettings/AppSettings";
import Modules from "../../components/Modules/Modules";
import { useParams } from "react-router";
import ReleaseNotes from "../../components/ReleaseNotes/ReleaseNotes";
import { setEdgeAppDetails, setEdgeDetails } from "../../reduxStore/actions";
import { Col, Row } from "antd";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { setApplicationDetails } from "../../reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../components/SectionHeading/Heading";
import AppDependency from "../../components/ApplicationDependencies/AppDependency";
import { useHistory } from "react-router-dom";

function AppDetailsComponent({ settingData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const isAppInstalled = queryParam.get("isAppInstalled");
  const applicationDetails = useSelector(
    (state) => state.setApplicationDetails
  );
  const [AppLIcense, setAppLIcense] = useState({});
  const [AppreleaseNotes, setAppreleaseNotes] = useState([]);
  const isAllDependenciesInstalled = useSelector(
    (state) => state?.setisAllDependenciesInstalled?.isAllDependenciesInstalled
  );

  useEffect(() => {
    if (!settingData || !edgeId) {
      history.push("/");
    }
  }, [settingData, edgeId, history]);
  useEffect(() => {
    let fetchEdgeDetails = apiEndPoints.FETCH_SINGLE_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchEdgeDetails,
    };

    axios
      .request(config)
      .then((response) => {
        localStorage.setItem(
          "edgeName",
          response?.data?.edgeDetails?.[0]?.name
        );

        dispatch(
          setEdgeAppDetails({
            payload: {
              data: response.data,
              isLoading: false,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    // License for Apps
    let fetchAppLicense = apiEndPoints.FETCH_APPLICATION_LICENSE.replace(
      "*typeId*",
      settingData?.type
    );

    let App_license = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchAppLicense,
    };

    axios
      .request(App_license)
      .then((response) => {
        setAppLIcense(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Release Notes
    // License for Apps
    let fetchreleaseNotes = apiEndPoints.FETCH_APPLICATION_RELEASENOTES.replace(
      "*typeId*",
      settingData?.type
    );

    let App_releaseNotes = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchreleaseNotes,
    };

    axios
      .request(App_releaseNotes)
      .then((response) => {
        // let reversedVersions = []
        // response.data.map((item)=>{
        //   reversedVersions.unshift(item)
        //   })

        setAppreleaseNotes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (isAppInstalled == "false") {
      let fetchAvailableApplicationDetails =
        apiEndPoints.FETCH_AVAILABLE_APPLICATION_DETAILS.replace(
          "*typeId*",
          settingData?.type
        );
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: fetchAvailableApplicationDetails,
      };

      axios
        .request(config)
        .then((response) => {
          dispatch(setApplicationDetails({ payload: response.data }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //App Details
      let fetchInstalledApplicationDetails =
        apiEndPoints.FETCH_INSTALLED_APPLICATION_DETAILS.replace(
          "*typeId*",
          settingData?.type
        );
      fetchInstalledApplicationDetails =
        fetchInstalledApplicationDetails.replace("*edgeId*", edgeId);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: fetchInstalledApplicationDetails,
      };

      axios
        .request(config)
        .then((response) => {
          dispatch(setApplicationDetails({ payload: response.data }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  setTimeout(() => {
    dispatch(
      setEdgeDetails({
        payload: {
          data: [
            {
              heading: "Application Name",
              detail: settingData?.displayName,
            },
            {
              heading: "Cluster Version",
              detail: applicationDetails?.applicationDetails?.version,
            },
            {
              heading: "Description",
              detail: applicationDetails.applicationDetails?.description,
            },
          ],
          isLoading: false,
        },
      })
    );
  }, 500);

  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Col flex="1 1 250px" style={{ display: "grid", rowGap: "10px" }}>
        <Row
          style={{
            borderRadius: "10px",
            height:
              applicationDetails.applicationDetails?.kind == "Helm"
                ? "90vh"
                : "45vh",
          }}
        >
          <Col
            className="gridContainer"
            style={{ width: "100%", height: "-webkit-fill-available" }}
          >
            <Details props={AppLIcense} />
          </Col>
        </Row>
        <Row
          style={{
            // background: "pink",
            borderRadius: "10px",
            height:
              applicationDetails.applicationDetails?.kind == "Helm"
                ? "0vh"
                : "45vh",
          }}
        >
          <Col
            style={{ width: "100%", height: "-webkit-fill-available" }}
            className="gridContainer"
          >
            {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : ( */}
            <Modules />
            {/* )} */}
          </Col>
        </Row>
      </Col>

      <Col
        flex="1 1 730px"
        style={{
          columnGap: "10px",
          // background: "pink",
          display: "grid",
          rowGap: "10px",
        }}
      >
        {/* <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              // background: "magenta",
              borderRadius: "10px",
              // padding: "30px",
              height: "10vh",
            }}
          >
            <Status headingVisible="true" />
          </Col>
        </Row> */}
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              // background: "magenta",
              borderRadius: "10px",
              // padding: "30px",
              height: "91vh",
            }}
          >
            {/* {isAppInstalled ? (
              <AppSettings settingData={settingData} />
            ) : (
              <div style={{ textAlign: "center", paddingTop: "100px" }}>
                <p>
                  The App is not installed in any edge. Please Go to Home Page,
                  select any edge, there by selecting applications and install
                  this app in the edge you want to.
                </p>
              </div>
            )} */}
            <AppSettings settingData={settingData} />
          </Col>
        </Row>
      </Col>

      <Col
        flex="1 1 250px"
        style={{
          borderRadius: "10px",
          rowGap: "10px",
          display: "grid",
        }}
      >
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            style={{
              height: isAllDependenciesInstalled ? "91vh" : "60vh",
              flex: "1",
            }}
          >
            {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <> */}
            <ReleaseNotes props={AppreleaseNotes} />
            {/* </>
              )} */}
            {/* asdf */}
          </Col>
        </Row>
        {/* <Row>
          <Col className="gridContainer" style={{ height: "45vh", flex: "1" }}>
            {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <> */}
        {/*<LicenseInfo />
             </>
              )} */}
        {/* asdf */}
        {/*</Col>
        </Row> */}
        <Row>
          <Col
            className="gridContainer"
            style={{
              height: isAllDependenciesInstalled ? "0vh" : "30vh",
              flex: "1",
              minHeight: "0px",
            }}
          >
            {/* {platformType == "unknown" ? (
              <ConfigurationError />
            ) : (
              <>
                <LicenseInfo />
              </>
            )} */}
            <>
              <AppDependency />
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AppDetailsComponent;
