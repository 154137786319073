import React, { useEffect, useState } from "react";
import Heading from "../SectionHeading/Heading";
import WarningIcons from "../WarningIcons/WarningIcons";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setEdgeStatus } from "../../reduxStore/actions";
import "./Widget.css";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Table } from "antd";

function Widget({ headingVisible }) {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const apiReloader = useSelector((state) => state.setApiReloader);
  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState([]);
  const [refactoredPods, setRefactoredPods] = useState([]);
  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);
  const [customHeight, setCustomHeight] = useState(0);
  const [isLoading, setisLoading] = useState(true);

  const adjustHeight = () => {
    let a = 0;
    let getMainContainer = document?.getElementsByClassName("gridContainer");
    let getTheAboveApp = getMainContainer?.[4]?.getElementsByClassName("App");
    // console.log(getMainContainer)
    let appHeight = getTheAboveApp?.[0]?.clientHeight;
    if (isSummaryVisible == true) {
      a = 0;
    } else {
      a = 12;
    }
    let finalHeight = getMainContainer?.[1]?.clientHeight - appHeight - 10 + a;
    // console.log("value of inner height",finalHeight, appHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, [isSummaryVisible]);

  const PodData = useSelector(
    (state) =>
      state?.setEdgeStatus?.data?.edgeServiceMetricsSummary
        ?.edgeApplicationPodMetrics
  );

  useEffect(() => {
    const filteredPods = PodData?.filter((app) => app?.isPlatform == true);
    setServiceData(filteredPods);
  }, [PodData]);

  useEffect(() => {
    if (serviceData?.length == 0) {
      return;
    }
    let correctedPods = [];
    serviceData?.map((pod, key) => {
      // console.log(pod);
      if (pod?.edgePodStatisticsList) {
        pod.edgePodStatisticsList.map((service, key) => {
          correctedPods.push({
            key: `service_${service.serviceNamePod}`,
            name: service.serviceNamePod,
            status: service.statusPod,
            cpu: service.cpuPod,
            memory: service.ramPod,
          });
        });
      }
    });
    setRefactoredPods(correctedPods);
  }, [serviceData]);

  const columns = [
    {
      title: "Pods",
      dataIndex: "name",
      // key: 'name',
      width: "25%",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: 'age',
      width: "25%",
      ellipsis: true,
    },
    {
      title: "CPU %",
      dataIndex: "cpu",
      width: "25%",
      ellipsis: true,
      render: (cpu) => <span>{parseFloat(cpu).toFixed(2)}</span>,
    },
    {
      title: "Memory GB",
      dataIndex: "memory",
      width: "25%",
      ellipsis: true,
      render: (memory) => <span>{parseFloat(memory).toFixed(2)}</span>,
    },
  ];

  const generateTable = () => {
    return (
      <Table
        columns={columns}
        // rowSelection={{
        //   ...rowSelection,
        //   checkStrictly,
        // }}
        pagination={false}
        dataSource={refactoredPods}
        size="small"
        scroll={{
          y: customHeight - 45,
        }}
        expandable={{
          // expandedRowRender: record => (<p>{record.children[0].name}</p>),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              // console.log("Recordddd: ", record);
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              );
            }
          },
        }}
      />
    );
  };

  //  podsModulesData : {data:[{},{}], selection:'pod/module'}
  return (
    <div className="App podStatusApp">
      {/* <div
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "44.5%",
            borderBottom: "1px solid #e2e2e2",
            marginTop:'-10px'
          }}
        >
          {headingVisible && <Heading text="Edge Core Runtime" />}
        </div> */}

      <div
        style={{
          // height: `${
          //   isSummaryVisible ? "calc(52vh - 40vh)" : "calc(80vh - 42vh)"
          // }`,
          height: `${customHeight}px`,
          // overflow: "auto",
          transition: "0.3s",
          // background:'pink'
        }}
      >
        <div className="podsTable">{generateTable()}</div>
      </div>
    </div>
  );
}

export default Widget;
