const initialState = [];

const setAvailableApps = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_AVAILABLEAPPS":
            return state = action.payload
        default:
            return state;
    }
}

export default setAvailableApps;