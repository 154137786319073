const initialState = {"data":[],"isLoading":true};

const setEdgesMetrics = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EDGES_METRICS":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setEdgesMetrics;
