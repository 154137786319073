const initialState = {type:'',payload:{}};

const setBrokerEdgeSettings = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BROKER_EDGE_SETTINGS":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setBrokerEdgeSettings;
