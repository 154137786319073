export const envSettings = {
  clientId: `${environmentSettings.clientId}`,
  metricsApiUrl:`${environmentSettings.metricsApiUrl}`,
  redirectUri: `${environmentSettings.redirectUri}`,
  postLogoutRedirectUri: `${environmentSettings.postLogoutRedirectUri}`,
  b2cName: `${environmentSettings.b2cName}`,
  solution: `${environmentSettings.solution}`,
  prefix: `${environmentSettings.prefix}`,
  version: `${environmentSettings.version}`,
  removeSolutionFromAuthority: environmentSettings.removeSolutionFromAuthority,
  connectivityMode: `${environmentSettings.connectivityMode}`,
  oidc_clientId: `${environmentSettings.oidc_clientId}`,
  oidc_redirectUri: `${environmentSettings.oidc_redirectUri}`,
  webapiUrl: `${environmentSettings.webapiUrl}`,
  auditLogUrl:`${environmentSettings.auditLogUrl}`,
  isAuthenticationEnabled: environmentSettings.isAuthenticationEnabled,
  isOfflineMode: environmentSettings.isOfflineMode,
  authority: `${environmentSettings.authority}`,

 
};
