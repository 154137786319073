import "./Widget.css";
import Heading from "../SectionHeading/Heading";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useState, useEffect } from "react";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";
import { Tooltip } from "antd";
import { PiGaugeBold } from "react-icons/pi";
import { getPrecise_TimeSpan } from "../../constants/commonFunction";

function Widget() {
  const matricsData = useSelector((state) => state.setData.data);
  const matricsData_isLoading = useSelector((state) => state.setData.isLoading);
  const graphData = useSelector((state) => state.setGraph.data);
  const graphData_isLoading = useSelector((state) => state.setGraph.isLoading);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  const nodeRAM = useSelector((state) => state.setEdgeStatus);

  const [chartWidth, setChartWidth] = useState(300);

  let rtime;
  let timeout = false;
  let delta = 200;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      let ele = document?.getElementById("contentContainer");

      let num = ele?.offsetWidth;
      // console.log(num)
      num = num;

      setChartWidth(num - 10);
    }
  }

  useEffect(() => {
    resizeend();
  }, []);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const timestamp = 1701410944;
  const date = new Date(timestamp * 1000);
  const datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  const getTheTime = (timeStamp) => {
    const date = new Date(timestamp * 1000);
    // const datevalues = [
    //    date.getFullYear(),
    //    date.getMonth()+1,
    //    date.getDate(),
    //    date.getHours(),
    //    date.getMinutes(),
    //    date.getSeconds(),
    // ];
    // console.log(datevalues)

    return date.getMinutes();
  };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <span className="label">{`${getPrecise_TimeSpan(
            payload?.[0]?.payload?.t
          )}`}</span>
          <span className="label">{` - ${parseFloat(
            payload?.[0]?.value
          ).toFixed(2)}`}</span>
        </div>
      );
    }
    return null;
  };

  const generateMetrixRow = (
    title,
    description,
    usage,
    total,
    chartData,
    graphLimit
  ) => {
    return (
      <tr>
        <td
          style={{
            borderBottom: "1px solid #f0f0f0",
            width: "50%",
            padding: "0px 10px",
            height: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "11px",
                gap: "5px",
                paddingRight: "5px",
                marginTop: "7px",
              }}
            >
              {/* <b style={{ fontSize: "18px" }}>{title}</b> */}
              {total.includes("Core in use") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "2px",
                  }}
                >
                  <span style={{ fontSize: "22px" }}>
                    {total.split(" ")[0]}
                  </span>
                  <span style={{ fontSize: "15px" }}> %</span>
                </div>
              )}
              {total.includes("GB") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "2px",
                  }}
                >
                  <span style={{ fontSize: "22px" }}>
                    {total.split(" ")[0]}
                  </span>
                  <span style={{ fontSize: "15px" }}>GB</span>
                </div>
              )}
            </div>
            <div>
              <span
                target="_blank"
                style={{
                  marginLeft: "auto",
                  textDecoration: "none",
                }}
              >
                {usage.includes("CPU") && (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[0]}
                    </span>
                    <span
                      style={{
                        color: "#989898",
                        fontSize: "14px",
                        // fontWeight: "700",
                        // color: "#000",
                      }}
                    >
                      {usage.split(" ")[1]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[2]}
                    </span>
                  </div>
                )}

                {usage.includes("Memory") && (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[0]}
                    </span>
                    <span
                      style={{
                        color: "#989898",
                        fontSize: "14px",
                        // fontWeight: "700",
                        // color: "#000",
                      }}
                    >
                      {usage.split(" ")[1]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[2]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")?.[3]}
                    </span>
                  </div>
                )}

                {usage.includes("Storage") && (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[0]}
                    </span>
                    <span
                      style={{
                        color: "#989898",
                        fontSize: "14px",
                        // fontWeight: "700",
                        // color: "#000",
                      }}
                    >
                      {usage.split(" ")[1]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")[2]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")?.[3]}
                    </span>
                    <span style={{ color: "#989898", fontSize: "14px" }}>
                      {usage.split(" ")?.[4]}
                    </span>
                  </div>
                )}
                <ResponsiveContainer width={chartWidth} height={70}>
                  <AreaChart
                    data={chartData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <RechartsTooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="v"
                      stroke="#00308F"
                      fill="#D1EBFF"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <span style={{ fontSize: "15px", color: "#989898" }}>
                  {description}
                </span>
                <span style={{ fontSize: "15px", color: "#989898" }}>
                  Last 2 hours
                </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const millicoresToCores = (millicores) => {
    return (millicores / 1000).toFixed(1);
  };

  function bytesToSize(bytes) {
    if (bytes == 0) return "n/a";
    const k = 1024; // 2^10
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const value = bytes / Math.pow(k, i);
    return `${value.toFixed(2)}`;
  }

  return (
    <div className="App contentApp" id="contentContainer">
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text="Performance" />
        {matricsData_isLoading && graphData_isLoading && <SpinnerLoader />}
        <a
          href={`/edgeResources?edgeId=${edgeId}`}
          style={{
            textDecoration: "none",
            color: General_color.info,
            marginLeft: "auto",
            paddingRight: "32px",
          }}
          className="Hyper_link"
        >
          {" "}
          <Tooltip title="Detail statics and control">view details</Tooltip>
        </a>
      </div>
      <div style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }}>
        <div className="graphs-container">
          {!matricsData_isLoading && !graphData_isLoading && (
            <>
              {matricsData.length > 0 && graphData.length > 0 ? (
                <>
                  {matricsData.length > 0 && graphData.length > 0 ? (
                    generateMetrixRow(
                      "",
                      `Total ${millicoresToCores(
                        matricsData?.[0]?.data?.[0].v * 1000
                      )} cores`,
                      `CPU utilization`,
                      `${Math.round(
                        matricsData?.[1]?.data?.[0].v
                      )} Core in use`,
                      graphData?.[0]?.data,
                      {
                        upper: "1.0 cores",
                        middle: "0.5 cores",
                        lower: "0.0 cores",
                      }
                    )
                  ) : (
                    <NoDataComponent message="No CPU data at the moment" />
                  )}

                  {matricsData.length > 2 && graphData.length > 1 ? (
                    generateMetrixRow(
                      "",
                      `Total ${bytesToSize(matricsData?.[2]?.data?.[0].v)} GB`,
                      `${Math.round(
                        matricsData?.[3]?.data?.[0].v
                      )}% Memory in use`,
                      `${(
                        (matricsData?.[3]?.data?.[0].v / 100) *
                        bytesToSize(matricsData?.[2]?.data?.[0].v)
                      ).toFixed(2)} GB used`,
                      graphData?.[1]?.data,
                      { upper: "12.0 GB", middle: "6.0 GB", lower: "0 MB" }
                    )
                  ) : (
                    <NoDataComponent message="No Memory data at the moment" />
                  )}

                  {matricsData.length > 4 && graphData.length > 2 ? (
                    generateMetrixRow(
                      "",
                      `Total ${bytesToSize(matricsData?.[4]?.data?.[0].v)} GB`,
                      `${Math.round(
                        matricsData?.[5]?.data?.[0].v
                      )}% Root Storage in use`,
                      `${(
                        (Math.round(matricsData?.[5]?.data?.[0].v) / 100) *
                        bytesToSize(matricsData?.[4]?.data?.[0].v)
                      ).toFixed(2)} GB used`,
                      graphData?.[2]?.data,
                      { upper: "25.0 GB", middle: "12.5 GB", lower: "0 GB" }
                    )
                  ) : (
                    <NoDataComponent message="No Storage data at the moment" />
                  )}
                </>
              ) : (
                <NoDataComponent />
              )}
            </>
          )}

          {/* {!matricsData_isLoading &&
            matricsData.length === 0 &&
            !graphData_isLoading &&
            graphData.length === 0 && <NoDataComponent message="hiii"/>} */}
        </div>
      </div>
    </div>
  );
}

export default Widget;
