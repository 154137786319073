import React, { useState, useEffect } from "react";
import Heading from "../SectionHeading/Heading";
import "./Dashboarddetails.css";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useDispatch, useSelector } from "react-redux";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";



function DashboardDetails({ inventoryData }) {
  const edgeList = useSelector((state) => state.setEdgesMetrics.data);
  const isLoading = useSelector((state) => state.setEdgesMetrics.isLoading);
  const [appsCount, setAppsCount] = useState(0);
  let dispatch = useDispatch()
 
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_ALL_AVAILABLE_APPS,
      
    };

    Axios.request(config)
      .then((response) => {
        setAppsCount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const tenant_name = `${
    localStorage.getItem("tenant") !== null
      ? localStorage.getItem("tenant")
      : ""
  }`;
  let result = tenant_name.charAt(0).toUpperCase() + tenant_name.slice(1);
  return (
    <div className="App dashboardDetailApp">
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        {/* <Heading text="Tenant Details" /> {isLoading && <SpinnerLoader />} */}
        <Heading text="Tenant Details" />
      </div>
      {/* <hr style={{marginTop: '30px' }}/> */}
      {/* {edgeList.length != 0 && (!isLoading && ( */}
      <div className="dashboardDetails" style={{}}>
        <div className="section">
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center",
            }}
          >
            <span className="heading">Tenant </span>
            <span className="detail">{`${result}`}</span>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center",
            }}
          >
            <span className="heading">Organization </span>
            <span className="detail">ABB</span>
          </div>
          <span
            style={{
              // scale: "1.5",
              borderRight: "1px solid #f0f0f0",
              color: "#fff",
            }}
          >
            |
          </span>
          {/* <div
            style={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center",
            }}
          >
            <span className="heading">Edge(s)</span>
            <span className="detail">
              {inventoryData[0]?.name == "undefined"
                ? "0"
                : // : inventoryData[0]?.name}{" "}
                  `${edgeList?.length == undefined ? "0" : edgeList?.length}`}
            </span>
          </div> */}
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center",
            }}
          >
            <span className="heading">Application(s)</span>
            <span className="detail">
              {inventoryData[0]?.name == "undefinedd" ? "0" : appsCount?.length}
            </span>
          </div>
          {/* 
           <span className="heading">Version</span>
           <span className="detail">v13.2.4-20230109</span> */}
        </div>
      </div>
      {/* ))} */}

      {/* {edgeList.length == 0 && !isLoading && <NoDataComponent />} */}
    </div>
  );
}

export default DashboardDetails;
