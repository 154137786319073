import React, { useEffect, useState } from "react";
import Heading from "../SectionHeading/Heading";
import WarningIcons from "../WarningIcons/WarningIcons";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {  setEdgeStatus } from "../../reduxStore/actions";
import { Tooltip } from "antd";

function Widget({ headingVisible }) {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();
  const apiReloader = useSelector((state) => state.setApiReloader);
  const AppsInstalled_count = useSelector(
    (state) => state.setEdgeAppDetails?.data?.applicationDetails?.appsInUse
  );
  const [platform_ServiceStatus, setplatformServiceStatus] = useState("-");
  const [application_Status, setApplicationStatus] = useState("-");
  const [controlPlaneStatus, setcontrolPlaneStatus] = useState("-");
 

  useEffect(() => {
    if (window.location.href.includes("unknown")) {
      setplatformServiceStatus("Pending");
      setApplicationStatus("Pending");
      setcontrolPlaneStatus("Connected");

      return;
    }

    let statusEdgeDetailsURL = apiEndPoints.FETCH_STATUS_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );
    //Fetch Edge Details Status
    let Fetch_Status = {
      method: "get",
      maxBodyLength: Infinity,
      url: statusEdgeDetailsURL,
    };
   
    axios
      .request(Fetch_Status)
      .then((response) => {
        dispatch(
          setEdgeStatus({ payload: { data: response.data, isLoading: false } })
        );

        if (response.data.platformServiceStatus != null) {
          setplatformServiceStatus(response.data.platformServiceStatus);
        }
        if (response.data.applicationStatus != null) {
          setApplicationStatus(response.data.applicationStatus);
        }
        if (response.data.cloudConnectivity != null) {
          setcontrolPlaneStatus(response.data.cloudConnectivity);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  const errors = [
    {
      name: "Cluster",
      details: "123 alerts total",
      icon: "critical",
    },
    {
      name: "Control Plane",
      details: "2 Components degraded",
      icon: "medium",
    },
    {
      name: "Storage",
      details: "Degraded",
      icon: "critical",
    },
    {
      name: "Hardware",
      details: "Degraded",
      icon: "medium",
    },
  ];

  const status = [
    {
      time: "2 mins ago",
      details: "The Api server has a 99th percentile latenct of 80 seconds",
      icon: "medium",
    },
    {
      time: "32 mins ago",
      details: "Disk device has rebooted",
      icon: "critical",
    },
    {
      time: "20 mins ago",
      details: "Cluster has overdue the CPU",
      icon: "medium",
    },
    {
      time: "12 mins ago",
      details: "33% target are down",
      icon: "critical",
    },
  ];

  const cluster_ControlPlane_data = [
    {
      name: "Management plane",
      details: controlPlaneStatus,
      configured: "",
      icon: controlPlaneStatus == "-" ? "unknown Status" : controlPlaneStatus,
    },
    {
      name: "Platform",
      details:
        platform_ServiceStatus == "Pending"
          ? "1 or more service(s) need attention"
          : platform_ServiceStatus == "Running"
          ? "All services up and running!"
          : platform_ServiceStatus == "Error"
          ? "1 or more service(s) has error"
          : platform_ServiceStatus,
      configured: "unknown",
      icon:
        platform_ServiceStatus == "-"
          ? "unknown Status"
          : platform_ServiceStatus,
    },
    {
      name: "Edgenius Applications",
      details:
        AppsInstalled_count == 0
          ? "No application installed"
          : application_Status == "Pending"
          ? "1 or more application(s) need attention"
          : application_Status == "Running"
          ? "All application(s) up and running!"
          : application_Status == "Error"
          ? "1 or more application(s) has error"
          : application_Status,
      configured: "unknown",
      icon: application_Status == "-" ? "unknown Status" : application_Status,
    },
  ];

  const desciptiveData = (s, i) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "10px",
          padding: "10px",
        }}
        key={i}
      >
        <WarningIcons type={s.icon} />

        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <span style={{ fontSize: "11px" }}>{s.time}</span>
          <span style={{ fontSize: "15px", fontFamily: "ABBvoice-bold" }}>
            {s.details}
          </span>
        </div>
        <a
          target="_blank"
          href="https://observability.cod.abb.com"
          style={{ marginLeft: "auto", textDecoration: "none" }}
        >
          <div className="Hyper_link">
            <span style={{ fontWeight: "500" }}>View Details</span>
          </div>
        </a>
      </div>
    );
  };

  const Errors_div = (e, i) => {
    return (
      <div
        key={`container_${i}`}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <Tooltip title={e.details}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningIcons type={e.icon} />
            <b
              style={{
                fontSize: "18px",
                color: "#00308F",
                marginLeft: "8px",
              }}
            >
              {e.name}
            </b>
          </div>
        </Tooltip>
      </div>
    );
  };

  if (window.location.pathname === "/edgedetails") {
    return (
      <div
        className="App"
        style={{
          height: "-webkit-fill-available",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "44.5%",
            borderBottom: "1px solid #e2e2e2",
            marginTop:'-10px'
          }}
        >
          {headingVisible && <Heading text="Status" />}
        </div> */}

        <div
          style={{
            // marginTop: "10px",
            // paddingBottom: "10px",
            // borderBottom: "1px solid #d2d2d2",
            display: "flex",
            flex: "1",
            flexDirection: "row",
            justifyContent: "space-between",
            columnGap: "80px",
          }}
        >
          {cluster_ControlPlane_data.map((e, i) => Errors_div(e, i))}
        </div>

        {/* <div style={{ marginTop: "10px" }}>
          <div className="TableData">
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      width: "60%",
                      paddingLeft: "15px",
                    }}
                  >
                    Pod Name
                  </th>
                  <th
                    style={{ padding: "8px", textAlign: "left", width: "40%" }}
                  >
                    Pod Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {pod_Status.map((s, i) => (
                  <tr key={i}>
                    <td
                      style={{
                        padding: "8px",
                        width: "50%",
                        paddingLeft: "15px",
                      }}
                    >
                      {s.podName}
                    </td>
                    <td style={{ padding: "8px" }}>{s.statusText}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    );
  } else {
    return (
      <div className="App">
        <div
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "44.5%",
            borderBottom: "1px solid #f0f0f0",
            marginTop: "-10px",
          }}
        >
          {headingVisible && <Heading text="Status" />}
        </div>

        <div
          style={{
            marginTop: "30px",
            padding: "10px",
            borderBottom: "1px solid #d2d2d2",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {errors.map((e, i) => Errors_div(e, i))}
        </div>

        <div style={{ marginTop: "20px" }}>
          {status.map((s, i) => desciptiveData(s, i))}
        </div>
      </div>
    );
  }
}

export default Widget;
