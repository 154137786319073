const initialState = null;

const setisAllDependenciesInstalled = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_DEPENDENCY_INSTALLED":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setisAllDependenciesInstalled;
