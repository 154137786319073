const initialState = {isNew:false, data:[], newData:[]};

const setPageNotifications = (state = initialState, action) =>{
    switch (action.type) {
        case "SET_PAGE_NOTIFICATION":
            return state = action.payload
        default:
            return state;
    }
}

export default setPageNotifications;