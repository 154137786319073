const initialState = {"data":[], "isLoading":true};

const setData = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_METRICS":
            return state = action.payload
        default:
            return state;
    }
}

export default setData;