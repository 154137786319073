import React from 'react'
import Widget from './Widget'


function EdgeSettings() {
  return (
  <Widget />
  )
}

export default EdgeSettings