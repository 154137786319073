import React, { useEffect, useState } from "react";
import Heading from "../SectionHeading/Heading";
import "./Widget.css";
// import Switch from "react-switch";
import { Button, Input, Form, Select, message, Popover, Modal } from "antd";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setInstallAppsReloader,
  setPageNotifications,
} from "../../reduxStore/actions";
import { deploymentProgess } from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";

import { MdOutlineDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";

import { CreateGuid, generateTime } from "../../constants/commonFunction";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";
import { setisAllDependenciesInstalled } from "../../reduxStore/actions";
import MethodInvocation from "../MethodInvocation/MethodInvocation";

function Widget({ settingData, hidepopup, containerid }) {
  const [form] = Form.useForm();

  const [isContainedPage, setIsContainedPage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sudoState, setSudoState] = useState(0);
  const [showAppInstallModal, setShowAppInstallModal] = useState(false);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const [isAppInstalled, setIsAppInstalled] = useState(
    "true" === queryParam.get("isAppInstalled")
  );
  const [popoverVisible, setPopoverVisible] = useState(false);

  const [appSystemSettings, setSystemSettings] = useState({});
  const [haveAppSettings, setHaveAppSettings] = useState(false);
  const [ischanged, setIsChanged] = useState(true);
  const [customHeight, setCustomHeight] = useState(0);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [isDeletingKey, setIsDeletingKey] = useState(false);
  const [view, setView] = useState(true);
  const [view_Configuration, setView_Configuration] = useState(0);
  const [allAvailableSystems, setAllAvailableSystems] = useState([]);
  const [reloadAvailableSystem, setReloadAvailableSystem] = useState(0);
  const [isSystemKeyAdded, setIsSystemKeyAdded] = useState(false);
  let [collectedSystem, setCollectedSystem] = useState(
    // localStorage.getItem("selectedData") == null
    //   ? {}
    //   : JSON.parse(localStorage.getItem("selectedData")).edgeId == edgeId
    //   ? JSON.parse(localStorage.getItem("selectedData")).system
    //   :
    {}
  );
  const [isFetchingAllSystem, setIsFetchingAllSystem] = useState(false);
  let [changedValues, setChangedVAlues] = useState([]);
  const [applicationObjectId, setApplicationObjectId] = useState("");
  let curr_App_reloaderValue = useSelector(
    (state) => state.setInstallAppsReloader
  );
  const [systemForm] = Form.useForm();
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [allSettings, setAllSettings] = useState([]);
  const [allMethods, setAllMethods] = useState([]);
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const [sysToDlete, setSysToDelte] = useState("");

  const adjustHeight = () => {
    let getMainContainer = document?.getElementsByClassName("gridContainer");

    let finalHeight = getMainContainer?.[2]?.clientHeight - 150;
    // console.log("value of inner height",getMainContainer)
    setCustomHeight(finalHeight);
  };

  const [dependencyData, setDependencyData] = useState(undefined);
  // const asdf_dependencyData = useSelector(
  //   (state) => state.setisAllDependenciesInstalled
  // );
  const appState = useSelector(
    (state) => state.setApplicationDetails.applicationDetails.state
  );
  let isAllDependenciesInstalled = "";
  useEffect(() => {
    console.log("this rtunned");
    //Fetch Dependency Data
    let fetchAppDependency = apiEndPoints.FETCH_APP_DEPENDENCIES.replace(
      "*edgeId*",
      edgeId
    );
    fetchAppDependency = fetchAppDependency.replace(
      "*typeId*",
      settingData?.type
    );
    let appDependency = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchAppDependency,
    };

    axios
      .request(appDependency)
      .then((response) => {
        setDependencyData(response.data);
        dispatch(
          setisAllDependenciesInstalled({
            payload: response.data,
          })
        );
        isAllDependenciesInstalled = response.data.isAllDependenciesInstalled;

        tempFunction(response.data);
      })
      .catch((error) => {
        // if (window.location.pathname.includes("/allapplications")) {
        let notiMsg =
          "One or more Application dependencies are not available. Please check the application documentation or contact System Administrator";
        // message.error(notiMsg, 15);

        AddEdgeSettingsNotification(
          notiMsg,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: settingData?.displayName,
            successMessage: notiMsg,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        // }
        console.log(error);
        hidePreviousModal();
      });
  }, [containerid]);

  const isAllDependenciesInstalled_ui = useSelector(
    (state) => state?.setisAllDependenciesInstalled?.isAllDependenciesInstalled
  );
  // const dependencyData = useSelector(
  //   (state) => state.setisAllDependenciesInstalled
  // );

  const [opendependencyModal, setOpendependencyModal] = useState(false);
  const closeDependencyModal = () => {
    setOpendependencyModal(false);
  };

  const [dataSource, setdataSource] = useState([]);
  const [installedCount, setinstalledCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  let sudo_dataSource = [];
  let AvailableApplications = useSelector((state) => state.setAvailableApps);
  const tempFunction = (ddd) => {
    // console.log(ddd, dependencyData);
    if (ddd === dependencyData) {
      return;
    }
    let _dependencyData = ddd != undefined ? ddd : dependencyData;
    let temp_dataSource = _dependencyData
      ? Object?.keys(_dependencyData?.dependencies)?.map((key) => ({
          key: key,
          type: key.split(",")[2],
          name: key.split(",")[1],
          status: _dependencyData.dependencies[key],
        }))
      : ["falseldale"];
    setdataSource(temp_dataSource);
    sudo_dataSource = temp_dataSource;

    console.log("Insidfe tmp", sudo_dataSource);
    // let _temp_dataSource = [
    //   { ...temp_dataSource[0] },
    //   { ...temp_dataSource[0] },
    // ];
    // _temp_dataSource[1].type = "abb.edgenius.application.modbusconnect";
    // console.log("trmep DFata", _temp_dataSource);

    let thisDependentApp = temp_dataSource.map(
      (dataSrc, index) =>
        dataSrc.status === false && temp_dataSource[index].type
    );
    // console.log("Thi dependednt app", thisDependentApp);

    let inModal = thisDependentApp.map(
      (type, index) =>
        AvailableApplications.filter((app, i) => app.type === type)[0]
    );
    // console.log("foiun the dependent", inModal);
    setinstalledCount(temp_dataSource?.filter((app) => app?.status)?.length);
    settotalCount(temp_dataSource?.length);
  };

  const showDependecyModal = () => {
    tempFunction();
    setOpendependencyModal(true);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  useEffect(() => {
    setIsFetchingAllSystem(true);
    setAllAvailableSystems([]);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_SYSTEM,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("yehi hai", response.data);
        setAllAvailableSystems(response.data);
        setIsFetchingAllSystem(false);
      })
      .catch((error) => {
        setIsFetchingAllSystem(false);
        console.log(error);
      });
  }, [reloadAvailableSystem]);

  // let changedValues = [];

  const collectChangeValues = (value, target, presentationType) => {
    setIsChanged(false);

    let foundIndex = changedValues.findIndex(
      (item) => item.targetPath === target
    );

    if (foundIndex == -1) {
      changedValues.push({
        value: value,
        targetPath: target,
        presentationType: presentationType,
      });
    } else {
      changedValues[foundIndex].value = value;
    }

    setSudoState((prev) => prev + 1);
    console.log(changedValues);
  };

  let selectedSettings = [];

  const showDepencyORinstall = (temptemp) => {
    setTimeout(() => {
      console.log(
        "asdf_dependencyData",
        isAllDependenciesInstalled_ui,
        isAllDependenciesInstalled
      );
      if (isAllDependenciesInstalled === false) {
        //have dependencies
        showDependecyModal();
        console.log("show 1");
      } else if (isAllDependenciesInstalled === true) {
        //no depoendency

        if (
          (!temptemp
            ? true
            : temptemp.filter(
                (sectionStting) => sectionStting.mandatory == true
              ).length == 0) &&
          window.location.pathname !== "/appDetails"
        ) {
          handleApplyy();
          console.log("handleApplyy clicked 4");
        } else {
        }
      } else {
        console.log("This is - ", isAllDependenciesInstalled);
      }
    }, 500);
  };
  useEffect(() => {
    const param = window.location.pathname;
    setIsContainedPage(param !== "/appDetails"); //true on main page
    let fetchApplicationSettings;

    if (param !== "/appDetails") {
      fetchApplicationSettings =
        apiEndPoints.FETCH_AVAILABLE_APPLICATION_SETTINGS;
    } else {
      if (isAppInstalled) {
        // console.log("1")
        fetchApplicationSettings =
          apiEndPoints.FETCH_APPLICATION_SETTINGS.replace("*edgeId*", edgeId);
      } else {
        // console.log("2")
        fetchApplicationSettings =
          apiEndPoints.FETCH_AVAILABLE_APPLICATION_SETTINGS;
      }
    }

    fetchApplicationSettings = fetchApplicationSettings.replace(
      "*typeId*",
      settingData?.type
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchApplicationSettings,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("App settings", response.data);
        setIsApiLoaded(true);
        // response.data.settings[0].mandatory = true;
        let settings = response?.data?.settings;
        let allSystemVal = settings.filter(
          (set) => set.sectionName == "System Configuration"
        )?.[0]?.properties?.[0]?.value;
        setCollectedSystem(allSystemVal);
        console.log("ye hua system collect", allSystemVal);
        setAllMethods(response?.data?.methods);
        if (settings?.length == 0) {
          setIsDataPresent(false);
          showDepencyORinstall();
          console.log("upper");
          setHaveAppSettings(false);
          return;
        } else {
          setIsDataPresent(true);
          setHaveAppSettings(true);
        }

        setAllSettings(settings);

        settings?.map((sys, key) => {
          if (sys.sectionName == "System Configuration") {
            setSystemSettings(settings?.[key]);
          }
        });
        setApplicationObjectId(settings?.[0]?.applicationObjectId);
        console.log("settings for applications id launch", settings)
        if (param !== "/appDetails") {
          setTimeout(() => {
            showDepencyORinstall(settings);
          }, 1000);
          console.log("lower");
        }
      })
      .catch((error) => {
        console.log(error);
        let notiMsg = "Application installation intitiation failed.";
        message.error(notiMsg);
        AddEdgeSettingsNotification(
          `Application installation intitiation failed.`,
          `AppRelatedUpdate_${CreateGuid()}`,
          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: settingData?.displayName,
            successMessage: `Application installation intitiation failed.`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        setIsApiLoaded(true);
      });
  }, [sudoState]);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const formReset = () => {
    // renderSettings();
    // console.log('clicked');
    // setSudoState(prev=>prev+1);
    form.resetFields();
    // form.setFieldsValue({ KeepAlivePeriod: 'Hello world!', CommunicationTimeout:'123' });
    changedValues = [];
  };

  const deleteInstalledSys = (key) => {
    delete collectedSystem[key];
    collectChangeValues(
      "",
      "#/controlsystemconnect/properties/associatedSystems",
      "System"
    );
    localStorage.setItem(
      "selectedData",
      JSON.stringify({ edgeId: edgeId, system: collectedSystem })
    );
    setSudoState((prev) => prev + 1);
  };

  const refactorValues = (obj) => {
    // if (obj == null) return;
    return (
      collectedSystem != undefined &&
      Object.keys(collectedSystem)?.map((key) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",

                flexDirection: "column",
                // gap: '5px',
              }}
              className="overFlowText"
            >
              <span style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}>
                {key}
              </span>
              <span style={{ fontFamily: "ABBVoice", fontSize: "15px" }}>
                {collectedSystem?.[key]?.value}
              </span>
            </div>
            <div>
              <MdOutlineDelete
                fontSize={20}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deleteInstalledSys(key);
                }}
              />
            </div>
          </div>
        );
      })
    );

    //  // console.log(obj[key]) // baz
  };
  const renderInputs = (ele) => {
    switch (ele.presentationType) {
      case "Select":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
            }}
          >
            {/* <div style={{ flex: "1" }}><span style={{ fontSize: "15px" }}>{ele.name}</span></div> */}
            <Form.Item
              name={ele.name.replace(/\s/g, "")}
              // label={ele.name.replace(/\s/g, "")}
              label={ele.name}
              style={{ marginBottom: "0px" }}
              class="text_1"
            >
              <Select
                style={{
                  width: "100%",
                  height: "35px",
                  gap: "4px",
                }}
                defaultValue={ele.value}
                onChange={
                  (e) =>
                    collectChangeValues(e, ele.targets[0], ele.presentationType)
                  // console.log(e.target.value, ele.targets[0])
                }
              >
                {ele.valueDomain.map((option, key) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Option value={option} key={key}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Switch defaultChecked onChange={onSwitchChange} /> */}

            <span
              style={{ fontSize: "15px", marginBottom: "15px" }}
              class="text_1"
            >
              {ele.description}
            </span>
          </div>
        );
      case "CheckBox":
        return (
          <div
            style={{
              display: "flex",
              columnGap: "10px",
            }}
          >
            <div
              style={{
                height: "40px",
                padding: "8px, 12px, 8px, 12px",
                borderRadius: "4px",
                border: "1px",
                gap: "8px",
              }}
            >
              {/* <Checkbox
                onChange={(e) =>

                  collectChangeValues(e.target.value, ele.targets[0], ele.presentationType)
                }
              ></Checkbox> */}
              <input
                type="checkbox"
                checked={
                  ele.value == "False" ||
                  ele.value == "false" ||
                  ele.value == null ||
                  ele.value == false
                    ? false
                    : true
                }
                onChange={(e) => {
                  ele.value = e.target.checked;
                  collectChangeValues(
                    e.target.checked,
                    ele.targets[0],
                    ele.presentationType
                  );
                }}
              />
            </div>
            <div style={{ flex: "1" }} class="text_1">
              {ele.name}
            </div>
          </div>
        );
      case "Text":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <div style={{ flex: "1" }}><span style={{ fontSize: "15px" }}>{ele.name}</span></div> */}
            {/* <div style={{ width: "100%"}}> */}
            <Form.Item
              name={ele.name.replace(/\s/g, "")}
              // label={ele.name.replace(/\s/g, "")}
              label={ele.name}
              class="text_1"
              style={{ marginBottom: "0px" }}
            >
              <Input
                defaultValue={ele.value}
                onChange={(e) =>
                  collectChangeValues(
                    e.target.value,
                    ele.targets[0],
                    ele.presentationType
                  )
                }
              />
            </Form.Item>
            <span
              style={{ fontSize: "15px", marginBottom: "15px" }}
              class="text_1"
            >
              {ele.description}
            </span>
            {/* </div> */}
          </div>
        );
      case "System":
        return <>{refactorValues(ele.value)}</>;
      default:
        break;
    }
  };

  const renderSettings = (settingArray) => {
    return settingArray?.properties?.map((ele, key) => (
      <div key={key}>{renderInputs(ele)}</div>
    ));
  };

  let AllInstalledApp = useSelector((state) => state.setInstalledApps);

  const dispatch = useDispatch();

  // const AvailableApplications = useSelector((state) => state.setAvailableApps);
  let popoverId = useSelector((state) => state.deploymentProgess);

  useEffect(() => {
    // if (isContainedPage) {
    //   if (popoverId === settingData?.name) {
    //     let asdf = popoverId.replace(/\s/g, "");
    //     const ele = document.getElementById(asdf);
    //     ele.setAttribute("style", "display:none;");
    //   }
    // }
  }, [popoverId]);

  const handleApply = () => {
    // hide();
    setShowAppInstallModal(true);
  };

  let notiMsg;
  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const hidePreviousModal = () => {
    console.log("clicked", containerid);
    hidepopup(containerid);
    // let thisElement = document.getElementById(containerid);
    // console.log(thisElement);
    // if (thisElement != null) {
    //   thisElement.style.visibility = "hidden";
    // }
  };

  const handleApplyy = () => {
    setIsSaving(true);
    setIsChanged(true);
    setIsApiLoaded(false);

    const updateUrlParam = () => {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.replace(
        "isAppInstalled=false",
        "isAppInstalled=true"
      );
      window.history.replaceState(null, "", newUrl);
    };

    if (isAppInstalled == true) {
      let installed_data = JSON.stringify({
        ApplicationTypeId: settingData?.type,
        EdgeId: edgeId,
        ApplicationModelId: settingData?.model,
        isApplicationInstalled: isAppInstalled,
        applicationObjectId: applicationObjectId,
        applicationSettingInstallationConfig: changedValues,
        systemConfig: collectedSystem == undefined ? {} : collectedSystem,
      });
      console.log("updating the app", JSON.parse(installed_data), allSettings);
      let installed_config = {
        method: "put",
        maxBodyLength: Infinity,
        url: apiEndPoints.UPDATE_INSTALLED_APP,

        data: installed_data,
      };

      axios
        .request(installed_config)
        .then((response) => {
          setIsSaving(false);
          setIsApiLoaded(true);
          setShowAppInstallModal(false);

          AddEdgeSettingsNotification(
            `Application settings applied successfully`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              setting_type: settingData?.type,
              settingSectionName: settingData?.displayName,
              operation_type: "configure",
              appName: settingData?.displayName,
              edgeName: localStorage.getItem("edgeName"),
              successMessage: `Application settings applied successfully`,
              time: generateTime(),
              isComplete: false,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
          setTimeout(() => {
            hidePreviousModal();
          }, 1000);
        })
        .catch((error) => {
          setIsSaving(false);
          setIsApiLoaded(true);
          console.log(error);

          let notiMsg = "Application settings failed";
          message.error(notiMsg);
          AddEdgeSettingsNotification(
            `Application settings failed`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: settingData?.displayName,
              successMessage: `Application settings failed`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );
        });
      setTimeout(() => {
        hidePreviousModal();
      }, 1000);
    } else {
      let data = JSON.stringify({
        ApplicationTypeId: settingData?.type,
        EdgeId: edgeId,
        ApplicationModelId: settingData?.model,
        applicationSettingInstallationConfig: changedValues,
        systemConfig: collectedSystem == undefined ? {} : collectedSystem,
      });
      console.log("ye chala");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: apiEndPoints.INSTALL_APPLICATION,

        data: data,
      };

      // console.log(JSON.parse(data))
      axios
        .request(config)
        .then((response) => {
          if (!isContainedPage) {
            setIsAppInstalled(true);
            updateUrlParam();
          }
          setIsSaving(false);
          setIsApiLoaded(true);
          AddEdgeSettingsNotification(
            `Application installation initiated successfully`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              setting_type: settingData?.type,
              settingSectionName: settingData?.displayName,
              operation_type: "configure",
              edgeName: localStorage.getItem("edgeName"),
              appName: settingData?.displayName,
              successMessage: `Application installation initiated successfully`,
              time: generateTime(),
              isComplete: false,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );

          setTimeout(() => {
            hidePreviousModal();
          }, 1000);

          dispatch(deploymentProgess({ payload: settingData?.name }));
          dispatch(
            setInstallAppsReloader({ payload: curr_App_reloaderValue + 1 })
          );
        })
        .catch((error) => {
          setIsSaving(false);
          console.log(error);
          setIsApiLoaded(true);
          let notiMsg = "Application installation failed";
          message.error(notiMsg);
          AddEdgeSettingsNotification(
            `Application installation failed`,
            `AppRelatedUpdate_${CreateGuid()}`,
            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: settingData?.displayName,
              successMessage: `Application installation failed`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );
          setTimeout(() => {
            hidePreviousModal();
          }, 1000);
        }).finally(()=>{
          setSudoState((prev) => prev + 1);
        });
    }
  };

  const installKey = (values) => {
    console.log(values);
    if (values.selectedSystem == undefined) return;

    let actualVal = JSON.parse(values.selectedSystem);
    if (collectedSystem == null) {
      setCollectedSystem({
        [actualVal.id]: { value: actualVal.name },
      });
    } else {
      Object.assign(collectedSystem, {
        [actualVal.id]: { value: actualVal.name },
      });
    }

    setSudoState((prev) => prev + 1);
    collectChangeValues(
      "",
      "#/controlsystemconnect/properties/associatedSystems",
      "System"
    );
    console.log("Collected Systems", collectedSystem);
    localStorage.setItem(
      "selectedData",
      JSON.stringify({ edgeId: edgeId, system: collectedSystem })
    );
    // console.log("SystemSEttings", appSystemSettings.properties[0].value);
  };

  const addtheSystem = (values) => {
    if (values.keyname === undefined) return;

    const existingKey = allAvailableSystems.find(
      (system) => system.name.toLowerCase() === values.keyname.toLowerCase()
    );

    if (existingKey) {
      message.warning("The key name already exists. Please add a new key.", 5);
      return;
    }

    setIsSystemKeyAdded(true);

    let data = JSON.stringify({
      name: values.keyname,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.ADD_NEW_SYSTEM,

      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let newKeyToAdd = response.data;
        installKey({
          selectedSystem: JSON.stringify(newKeyToAdd),
        });

        setIsSystemKeyAdded(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSystemKeyAdded(false);
      })
      .finally(() => {
        setPopoverVisible(false);
      });
  };

  const deleteThisKey = () => {
    let id = JSON.parse(sysToDlete).id;
    let delteSys = apiEndPoints.DELETE_SYSTEM.replace("*systemId*", id);

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: delteSys,
    };
    // console.log(id)
    setIsDeletingKey(true);

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setIsDeletingKey(false);
        systemForm.resetFields();
        setReloadAvailableSystem((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
        setIsDeletingKey(false);
        setReloadAvailableSystem((prev) => prev + 1);
      });
  };

  const popoverToKeyAdd = () => {
    return (
      <>
        {" "}
        <div
          className="screenTabs"
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            justifyContent: "space-around",
          }}
        >
          <span
            className={
              view == true
                ? "viewOptions active heading_2"
                : "viewOptions heading_2"
            }
            onClick={() => setView(true)}
          >
            New Key
          </span>

          <span
            className={
              view == false
                ? "viewOptions active heading_2"
                : "viewOptions heading_2"
            }
            onClick={() => {
              setView(false);
              setReloadAvailableSystem((prev) => prev + 1);
            }}
          >
            Available keys
          </span>
        </div>
        {view && (
          <Form
            layout="vertical"
            style={{
              width: 250,
            }}
            onFinish={addtheSystem}
          >
            <Form.Item label="Name" required name="keyname" class="text_1">
              <Input />
            </Form.Item>

            <Form.Item style={{ marginBottom: "10px" }}>
              <div
                className="button-options"
                style={{
                  justifyContent: "end",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  type="primary"
                  // className="button-option"
                  htmlType="submit"
                  loading={isSystemKeyAdded}
                  // text="Register Identity Provider"
                  style={{
                    background: "black",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    border: "0px",
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Add Key
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {!view && (
          <Form
            layout="vertical"
            style={{
              width: 250,
            }}
            onFinish={installKey}
            form={systemForm}
          >
            <Form.Item label="Keys" name="selectedSystem">
              <Select onChange={(value) => setSysToDelte(value)}>
                {allAvailableSystems.map((sys) => {
                  return (
                    <Select.Option value={JSON.stringify(sys)}>
                      {sys.name}
                    </Select.Option>
                  );
                })}
              </Select>
              {isFetchingAllSystem && (
                <div
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "7px",
                  }}
                >
                  <SpinnerLoader />
                </div>
              )}
            </Form.Item>

            <Form.Item style={{ marginBottom: "10px" }}>
              <div
                className="button-options"
                style={{
                  justifyContent: "end",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  type="primary"
                  // className="button-option"
                  htmlType="submit"
                  // loading={isSavingIdentityProvider}
                  // text="Register Identity Provider"
                  style={{
                    background: "black",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    border: "0px",
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Get Key
                </Button>

                <Button
                  // type="primary"
                  // className="button-option"
                  //  htmlType="button"
                  loading={isDeletingKey}
                  onClick={() => deleteThisKey()}
                  // text="Register Identity Provider"
                  style={{
                    border: "1px solid #e12e2e",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    color: "#e12e2e",
                    backgroundColor: "unset",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Delete Key
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </>
    );
  };
  const AppSettingsContainer = () => {
    return (
      appSystemSettings?.properties?.length > 0 && (
        <>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            {" "}
            {/* <Form
              // {...layout}
              layout="vertical"
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              style={{ maxWidth: 600 }}
            > */}
            <div>
              <span style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}>
                Connection Keys
              </span>
              <br />
              <span
                style={{
                  fontFamily: "ABBVoice",
                  width: isContainedPage ? "560px" : "auto",
                  display: "block",
                  fontSize: "15px",
                }}
              >
                In order to establish a connection between your control system
                and your edge get and then copy the unique connection key listed
                below corresponding to your system to your control System
                configuration.
              </span>
            </div>
            {renderSettings(appSystemSettings)}
            {/* <a style={{display:'flex',alignItems:'center', marginLeft:'-5px'}}> <IoIosAdd fontSize={20}/> Get Key</a> */}
            <Popover
              id="popoverBtn"
              placement="right"
              content={
                <div style={{ padding: "10px" }}>{popoverToKeyAdd()}</div>
              }
              trigger="click"
              visible={popoverVisible}
              onVisibleChange={setPopoverVisible}
            >
              <span
                className="Hyper_link"
                style={{
                  cursor: "pointer",
                  width: "fit-content",
                  display: "flex",
                  marginLeft: "-5px",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                <IoIosAdd style={{ fontSize: "20px" }} />
                Get Key
              </span>
            </Popover>
            {/* </Form> */}
          </div>
        </>
      )
    );
  };

  const paintScreenTabs = () => {
    return allSettings
      .filter((appSetting) =>
        isContainedPage ? appSetting.mandatory == true : appSetting
      )
      .map((appSettings, key) => {
        return (
          <span
            className={
              view_Configuration == key ? "viewOptions active" : "viewOptions"
            }
            onClick={() => {
              setView_Configuration(key);
              setApplicationObjectId(appSettings?.applicationObjectId);
            }}
            style={{ padding: 0 }}
          >
            {`${appSettings.sectionName}`}
          </span>
        );
      });
  };

  const paintSettings = () => {
    return allSettings
      .filter((appSetting) =>
        isContainedPage ? appSetting.mandatory == true : appSetting
      )
      .map((appSettings, key) => {
        return (
          <div
            style={
              view_Configuration == key
                ? { display: "block" }
                : { display: "none" }
            }
          >
            {appSettings?.properties?.length > 0 ? (
              <div style={{ marginTop: "10px" }}>
                <div style={{ marginTop: "10px" }}>
                  <Form
                    disabled={appState == 1 || appState == 4 ? true : false}
                    layout="vertical"
                    style={
                      {
                        // width: 250,
                      }
                    }
                  >
                    {appSettings?.sectionName == "System Configuration"
                      ? AppSettingsContainer()
                      : renderSettings(appSettings)}
                  </Form>
                </div>
              </div>
            ) : (
              <NoDataComponent
                message={`No ${appSettings?.sectionName} at the moment`}
              />
            )}
          </div>
        );
      });
  };

  const paintActionButtons = (howmany) => {
    // if(allSettings.filter(sectionStting=>sectionStting.mandatory==true).length!==0){
    return (
      <>
        <div
          className="button-group"
          style={{
            display: "flex",
            columnGap: "20px",
            marginTop: "30px",
            justifyContent: "flex-end",
            padding: "0px 10px",
          }}
        >
          {howmany == "both" ? (
            <>
              <Button
                style={{
                  background: (appState == 1 || appState == 4 || isAppInstalled) && ischanged ? General_color.disable : "#1f1f1f",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                  cursor: (appState == 1 || appState == 4 || isAppInstalled) && ischanged ? "not-allowed" : "pointer",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontSize: "15px",
                  fontFamily: "ABBVoice",
                }}
                disabled={
                  (appState == 1 || appState == 4 || isAppInstalled) && ischanged ? true : false
                }
                // disabled={ischanged}
                onClick={
                  dependencyData == undefined
                    ? () => {
                        document.getElementById("notinoti").click();
                      }
                    : !isAppInstalled
                    ? isAllDependenciesInstalled_ui === true
                      ? () => {
                          handleApplyy();
                          console.log("Clicked apply 2");
                        }
                      : () => {
                          showDependecyModal();
                          console.log("Clicked open miodel2");
                        }
                    : () => {
                        handleApplyy();
                        console.log("Clicked apply 3");
                      }
                }
                loading={isSaving}
                // disabled={!ischanged ? true : false}
              >
                {appState == 2 || isAppInstalled
                  ? "Apply"
                  : `${
                      haveAppSettings
                        ? "Save Configuration and Install"
                        : "Install"
                    }`}
              </Button>
              <Button
                // sizeClass="medium"
                style={{
                  color:
                    appState == 1 || appState == 4
                      ? General_color.disable
                      : "#000",
                  // background:'#000',
                  borderRadius: "100px",
                  fontWeight: "500",
                  fontSize: "15px",
                  fontFamily: "ABBVoice",

                  display: "flex",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                }}
                disabled={appState == 1 || appState == 4 ? true : false}
                variant="outline"
                onClick={() => hidePreviousModal()}
              >
                Reset
              </Button>
            </>
          ) : howmany == "install" ? (
            <Button
              style={{
                background:
                  appState == 1 || appState == 4
                    ? General_color.disable
                    : "#1f1f1f",
                display: "flex",
                alignItems: "center",
                borderRadius: "31px",
                padding: "5px 10px",
                // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                cursor: "pointer",
                fontWeight: "500",
                border: "0px",
                color: "white",
                fontSize: "15px",
                fontFamily: "ABBVoice",
              }}
              disabled={appState == 1 || appState == 4 ? true : false}
              onClick={
                dependencyData == undefined
                  ? () => {
                      document.getElementById("notinoti").click();
                    }
                  : !isAppInstalled
                  ? isAllDependenciesInstalled_ui === true
                    ? () => {
                        handleApplyy();
                        console.log("Clicked apply 2");
                      }
                    : () => {
                        showDependecyModal();
                        console.log("Clicked open miodel2");
                      }
                  : () => {
                      handleApplyy();
                      console.log("Clicked apply 3");
                    }
              }
              loading={isSaving}
              // disabled={!ischanged ? true : false}
            >
              {appState == 2 || isAppInstalled
                ? "Apply"
                : `${
                    haveAppSettings
                      ? "Save Configuration and Install"
                      : "Install"
                  }`}
            </Button>
          ) : (
            <Button
              // sizeClass="medium"
              style={{
                color: "#000",
                // background:'#000',
                borderRadius: "100px",
                fontWeight: "500",
                fontSize: "15px",
                fontFamily: "ABBVoice",
              }}
              variant="outline"
              onClick={() => hidePreviousModal()}
            >
              Cancel
            </Button>
          )}
        </div>
      </>
    );
    // }
  };

  const viewss = ["Settings", "Actions"];
  const [selectOption, setSelectOption] = useState(viewss[0]);
  const changeView = (viewName) => {
    setSelectOption(viewName);
  };
  return (
    <div className="App" style={{ padding: 0 }}>
      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {window.location.pathname.includes("appDetails") ? (
          <div
            className="Heading_container"
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              width: "98%",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {viewss.map((view, i) => (
              <>
                {allMethods.length == 0 && i == 0 && (
                  <>
                    <span>
                      <Heading text={view} />
                    </span>
                  </>
                )}

                {allMethods.length > 0 && (
                  <>
                    <span
                      className={
                        selectOption == view
                          ? allMethods.length > 0
                            ? "viewOption activeView"
                            : ""
                          : "viewOption"
                      }
                      onClick={() => changeView(view)}
                    >
                      <Heading text={view} />
                    </span>
                    {0 == i && allMethods.length > 0 && <span>|</span>}
                  </>
                )}
              </>
            ))}

            {!isApiLoaded && <SpinnerLoader />}
          </div>
        ) : (
          <>
            <div
              style={{
                width: "590px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {isAllDependenciesInstalled_ui === false ? (
                <span>Please install dependencies</span>
              ) : (
                <Heading text={settingData?.displayName} />
              )}
              {!isApiLoaded && <SpinnerLoader />}
            </div>
          </>
        )}
      </span>
      {viewss[1] == selectOption && (
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: "10px",
            height: `${isContainedPage ? "42vh" : `${customHeight + 80}px`}`,
            fontFamily: "ABBVoice",
            padding: "10px",
          }}
        >
          <MethodInvocation data={allMethods} />{" "}
        </div>
      )}
      {viewss[0] == selectOption && (
        <>
          {window.location.pathname.includes("appDetails") ||
          allSettings.filter((sectionStting) => sectionStting.mandatory == true)
            .length !== 0 ? (
            <div>
              <div style={{ marginTop: "10px" }}></div>
              {isAllDependenciesInstalled_ui === true || !isContainedPage ? (
                <>
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "hidden",
                      paddingRight: "10px",
                      height: `${
                        isContainedPage ? "42vh" : `${customHeight}px`
                      }`,
                      fontFamily: "ABBVoice",
                      padding: "10px",
                    }}
                  >
                    {isApiLoaded && (
                      <>
                        {isContainedPage && (
                          <span style={{ fontSize: "18px" }}>
                            Please set and confirm below settings for
                            application deployment
                          </span>
                        )}
                        {!isDataPresent && <NoDataComponent />}
                        <div
                          className="screenTabs"
                          style={{
                            marginTop: isContainedPage ? "20px" : "10px",
                            marginBottom: "30px",
                          }}
                        >
                          {paintScreenTabs()}
                        </div>
                      </>
                    )}

                    {paintSettings()}
                  </div>
                  <>
                    {isApiLoaded && isDataPresent && paintActionButtons("both")}
                    {!isAppInstalled &&
                      !isDataPresent &&
                      paintActionButtons("install")}
                  </>
                </>
              ) : (
                paintActionButtons("single")
              )}
            </div>
          ) : (
            <>
              {isAllDependenciesInstalled_ui === false && (
                <>{paintActionButtons("single")}</>
              )}
            </>
          )}
        </>
      )}

      <Modal
        title="Information"
        open={opendependencyModal}
        onCancel={closeDependencyModal}
      >
        <div style={{ padding: "10px" }}>
          <span
            style={{
              fontFamily: "ABBVoice-bold",
              fontSize: "15px",
            }}
          >
            {`Please note:- ${installedCount}/${totalCount} dependency applications installed.`}
          </span>
          {/* )} */}
          <div style={{ marginTop: "20px" }}>
            {dataSource.map((dependency, index) => (
              <div
                className="text_1"
                key={index}
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <span
                  style={{ marginRight: "20px", minWidth: "60%" }}
                  className="overFlowText"
                >
                  {dataSource[index].name}
                </span>
                <span
                  style={{
                    color: dependency.status
                      ? General_color.success
                      : General_color.error,
                  }}
                >
                  {dependency.status ? "Installed" : "Not Installed"}
                </span>
              </div>
            ))}
          </div>
          <p
            style={{
              fontFamily: "ABBVoice-bold",
              fontSize: "15px",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            To ensure full functionality, please manually install the remaining
            dependencies from Applications Page.
          </p>
        </div>
        <Button
          style={{
            background: "black",
            display: "flex",
            alignItems: "center",
            borderRadius: "31px",
            padding: "5px 10px",
            // boxShadow: " 0px 4px 6px -1px #d2d2d2",
            cursor: "pointer",
            fontWeight: "500",
            border: "0px",
            color: "white",
            fontSize: "15px",
            fontFamily: "ABBVoice",
            marginLeft: "auto",
          }}
          onClick={closeDependencyModal}
        >
          Okay
        </Button>
      </Modal>
    </div>
  );
}

export default Widget;
