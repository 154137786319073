import React from "react";
import { Component } from "react";

export default class ErrorBoundary extends Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }
    state = { hasError: false };
    static getDerivedStateFromError(error: any) {
        console.log("Error", error);
        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
        console.log("Error", error);
    }
    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Something went wrong</h1>
                    <button type="button" onClick={() => this.setState({ hasError: false })}>
                        Try again?
                    </button>
                </div>
            );
        }
        return this.props.children;
    }
}
