const initialState = false;

const confirmUpgradeDialouge = (state = initialState, action) => {
  switch (action.type) {
    case "CONFIRM_DIALOUGE":
      return (state = action.payload);
    default:
      return state;
  }
};

export default confirmUpgradeDialouge;
