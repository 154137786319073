import "./Widget.css";
import { FaInfoCircle, FaServer } from "react-icons/fa";

import { IoAppsSharp } from "react-icons/io5";

import { useLayoutEffect, useState } from "react";
import { routes } from "../../constants/RoutesConstants";
import { Tooltip } from "antd";

import { IoIosHome } from "react-icons/io";
import { GoDownload } from "react-icons/go";
import { MdOutlineHistory } from "react-icons/md";

function Widget() {
  const [pathname, setPathname] = useState("");
  useLayoutEffect(() => {
    setPathname(window.location.pathname);
  }, []);
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "20px",
          rowGap: "15px",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 6px",
        }}
      >
        <a href={routes.LANDING_PAGE} style={{ color: "#000" }}>
          <span
            className={`${
              pathname === `${routes.LANDING_PAGE}`
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="Home">
              <IoIosHome />
            </Tooltip>
          </span>
        </a>
        <a href={routes.DASHBOARD_PAGE} style={{ color: "#000" }}>
          <span
            className={`${
              pathname.includes(`${routes.EDGEDETAILS}`) ||
              pathname.includes(`${routes.RESOURCE_PAGE}`) ||
              pathname === `${routes.DASHBOARD_PAGE}`
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="System Inventory">
              <FaServer />
            </Tooltip>
          </span>
        </a>
        <a href={routes.ALLTENANT_APPS_PAGE} style={{ color: "#000" }}>
          <span
            className={`${
              pathname === `${routes.ALLTENANT_APPS_PAGE}` ||
              pathname.includes(`${routes.ALLAPPLICATIONS_PAGE}`) ||
              pathname.includes("appDetails")
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="Applications">
              <IoAppsSharp />
            </Tooltip>
          </span>
        </a>

        <a href={routes.DOWNLOADS} style={{ color: "#000" }}>
          <span
            className={`${
              pathname === `${routes.DOWNLOADS}` ||
              pathname.includes(`${routes.DOWNLOADS}`)
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="Downloads">
              <GoDownload />
            </Tooltip>
          </span>
        </a>

        {/* <span className="menuOptions"><BiSupport /></span> */}
        <hr style={{ width: "100%" }} />
        <a href={routes.AUDITLOG} style={{ color: "#000" }}>
          <span
            className={`${
              pathname === `${routes.AUDITLOG}` ||
              pathname.includes(`${routes.AUDITLOG}`)
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="User Activity Audit Log">

        <MdOutlineHistory />
            </Tooltip>
          </span>
        </a>
        <a href={routes.INFORMATION} style={{ color: "#000" }}>
          <span
            className={`${
              pathname === `${routes.INFORMATION}` ||
              pathname.includes(`${routes.INFORMATION}`)
                ? "menuOptions active"
                : "menuOptions"
            }`}
          >
            <Tooltip title="Info">
              <FaInfoCircle />
            </Tooltip>
          </span>
        </a>
        {/* <a href={routes.SETTINGS_PAGE} style={{ color: "#000" }}> */}
        {/* <span */}
        {/* className={`${ */}
        {/* pathname.includes(`${routes.SETTINGS_PAGE}`) */}
        {/* ? "menuOptions active"
              : "menuOptions" */}
        {/* }`} */}
        {/* > */}
        {/* <IoIosSettings fontSize="25px" /> */}
        {/* </span> */}
        {/* </a> */}
        {/* <span className="menuOptions"> */}
        {/* <HiMail fontSize="25px" /> */}
        {/* </span> */}
      </div>
    </div>
  );
}

export default Widget;
